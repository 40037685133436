import { ApiRoutingUtils } from '@/Routing';
import {
  ApplyAddressChangesRequest,
  ApplyAddressChangesResponse,
  LabelPrintedStatusResponse,
  OrderAddressValidationRequest,
  OrderAddressValidationResponse,
} from './';
import { AddressType } from '@/Enums';
import { GenerateLabelsResponse } from './GenerateLabelsResponse';
import { GenerateLabelsRequest } from './GenerateLabelsRequest';
import { PrepareLabelsRequest } from './PrepareLabelsRequest';
import { rest } from '../Rest';
import { GetPackagesForOrderRequest } from './GetPackagesForOrderRequest';
import { GetPackagesForOrderResponse } from './GetPackagesForOrderResponse';
import { GetPacklinkProductsRequest } from '@/Api/Logistics/GetPacklinkProductsRequest';
import { GetPacklinkProductsResponse } from '@/Api/Logistics/GetPacklinkProductsResponse';

const url = ApiRoutingUtils.createUrl('logistics');

async function validateOrdersAddresses(
  request: OrderAddressValidationRequest,
): Promise<OrderAddressValidationResponse> {
  return rest.post(`${url}/validate-address`, request);
}

async function applyAddressChanges(request: ApplyAddressChangesRequest): Promise<ApplyAddressChangesResponse> {
  return rest.put(`${url}/apply-address`, request);
}

async function getFormattedAddress(orderId: number, addressType: AddressType): Promise<string> {
  return rest.get(`${url}/formatted-address`, {
    addressType,
    orderId,
  });
}

async function prepareLabels(request: PrepareLabelsRequest): Promise<void> {
  return rest.post(`${url}/prepare-labels`, request);
}

async function generateLabels(request: GenerateLabelsRequest): Promise<GenerateLabelsResponse> {
  return rest.get(`${url}/generate-labels`, request);
}

async function setLabelPrintedStatus(orderLabelIds: number[]): Promise<LabelPrintedStatusResponse> {
  return rest.put(`${url}/printed-label`, { orderLabelIds: orderLabelIds });
}

async function getPackagesForOrders(request: GetPackagesForOrderRequest): Promise<GetPackagesForOrderResponse> {
  return rest.get(`${url}/get-packages-for-order`, request);
}

async function getLogisticProducts(request: GetPacklinkProductsRequest): Promise<GetPacklinkProductsResponse> {
  return rest.post(`${url}/get-logistic-products`, request);
}

export const LogisticsApi = {
  validateOrdersAddresses,
  applyAddressChanges,
  getFormattedAddress,
  prepareLabels,
  generateLabels,
  setLabelPrintedStatus,
  getPackagesForOrders,
  getLogisticProducts,
};
