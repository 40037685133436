import { ApiRoutingUtils } from '@/Routing';
import { GetAdminAccountChartsRequest } from './Admin/GetAdminAccountChartsRequest';
import { GetAdminAccountChartsResponse } from './Admin/GetAdminAccountChartsResponse';
import { CreateAdminAccountChartRequest } from './Admin/CreateAdminAccountChartRequest';
import { CreateAdminAccountChartResponse } from './Admin/CreateAdminAccountChartResponse';
import { UpdateAdminAccountChartRequest } from './Admin/UpdateAdminAccountChartRequest';
import { UpdateAdminAccountChartResponse } from './Admin/UpdateAdminAccountChartResponse';
import { DeleteAdminAccountChartRequest } from './Admin/DeleteAdminAccountChartRequest';
import { DeleteAdminAccountChartResponse } from './Admin/DeleteAdminAccountChartResponse';
import { GetAccountChartsForPreviewRequest } from './GetAccountChartsForPreviewRequest';
import { GetAccountChartsForPreviewResponse } from './GetAccountChartsForPreviewResponse';
import { GetAccountChartsResponse } from './GetAccountChartsResponse';
import { CreateAccountChartResponse } from './CreateAccountChartResponse';
import { CreateAccountChartRequest } from './CreateAccountChartRequest';
import { UpdateAccountChartRequest } from './UpdateAccountChartRequest';
import { UpdateAccountChartResponse } from './UpdateAccountChartResponse';
import { DeleteAccountChartRequest } from './DeleteAccountChartRequest';
import { DeleteAccountChartResponse } from './DeleteAccountChartResponse';
import { rest } from '@/Api/Rest';
import { GetAccountChartsRequest } from './GetAccountChartsRequest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('account-charts');
const adminUrl = ApiRoutingUtils.createOnlineArchiveUrl('admin-account-charts');

/* User api */
const getAccountCharts = (request: GetAccountChartsRequest): Promise<GetAccountChartsResponse> => {
  return rest.get(url, request);
};

const getAccountChartsForPreview = (
  request: GetAccountChartsForPreviewRequest,
): Promise<GetAccountChartsForPreviewResponse> => {
  return rest.get(`${url}/preview`, request);
};

const createAccountChart = (request: CreateAccountChartRequest): Promise<CreateAccountChartResponse> => {
  return rest.post(url, request);
};

const updateAccountChart = (request: UpdateAccountChartRequest): Promise<UpdateAccountChartResponse> => {
  return rest.put(url, request);
};

const deleteAccountChart = (request: DeleteAccountChartRequest): Promise<DeleteAccountChartResponse> => {
  return rest.delete(`${url}/${request.id}`, {});
};

/* Admin api */
const getAccountChartsForAdmin = (request: GetAdminAccountChartsRequest): Promise<GetAdminAccountChartsResponse> => {
  return rest.get(adminUrl, request);
}; // getAccountChartsForAdmin

const createAccountChartForAdmin = (
  request: CreateAdminAccountChartRequest,
): Promise<CreateAdminAccountChartResponse> => {
  return rest.post(adminUrl, request);
}; // createAccountChartForAdmin

const updateAccountChartForAdmin = (
  request: UpdateAdminAccountChartRequest,
): Promise<UpdateAdminAccountChartResponse> => {
  return rest.put(adminUrl, request);
}; // updateAccountChartForAdmin

const deleteAccountChartForAdmin = (
  request: DeleteAdminAccountChartRequest,
): Promise<DeleteAdminAccountChartResponse> => {
  return rest.delete(`${adminUrl}/${request.id}`, {});
}; // deleteAccountChartForAdmin

export const AccountChartsApi = {
  user: {
    getAccountCharts,
    getAccountChartsForPreview,
    createAccountChart,
    updateAccountChart,
    deleteAccountChart,
  },
  admin: {
    getAccountChartsForAdmin,
    createAccountChartForAdmin,
    updateAccountChartForAdmin,
    deleteAccountChartForAdmin,
  },
};
