import React from 'react';
import styles from './EmptyActiveLogisticsModal.scss';
import { Modal } from '@/Components/Modal/Modal';
import { PagesRouting } from '@/Routing';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Link } from '@/Components/Controls/Link/Link';
import { useLocalization } from '@/Hooks';

type Props = {
  onClose: () => void;
};

export function EmptyActiveLogisticsModal({ onClose }: Props) {
  const {
    modalWindows: {
      addressValidationResolving: { EmptyActiveLogisticsModal },
    },
  } = useLocalization();

  return (
    <Modal modalClassName={styles.modal} onClose={onClose} shouldCloseOnOverlayClick={false} needRefDocsIcon={false}>
      <div>
        <h4>{EmptyActiveLogisticsModal.Title}</h4>
        <p>{EmptyActiveLogisticsModal.Text}</p>

        <div className={styles.buttonsWrapper}>
          <Link to={PagesRouting.SettingsPages.LogisticSettings}>
            <Button text={EmptyActiveLogisticsModal.LogisticSettings} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} />
          </Link>
        </div>
      </div>
    </Modal>
  );
}
