import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetMpTypesResponse } from '@/Api/MpType/GetMpTypesResponse';

const url = ApiRoutingUtils.createUrl('mp-types');

function getMarketplaceTypes(): Promise<GetMpTypesResponse> {
  return rest.get(url);
}

export const MpTypeApi = {
  getMarketplaceTypes,
};
