import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deutschePostInternetmarkeContractReducer,
  DeutschePostInternetmarkeContractState,
} from '../modules/DeutschePostInternetmarke/services/reducer';
import { logisticContractReducer, LogisticContractState } from '../modules/LogisticContract/services/reducer';

export type LogisticContractModalState = {
  actions: {
    create: (data: any) => void;
    delete: (data: any) => void;
    checkConnection: (data: any) => void;
  };
};

const initialState: LogisticContractModalState = {
  actions: {
    create: () => null,
    delete: () => null,
    checkConnection: () => null,
  },
};

const logisticContractModalSlice = createSlice({
  name: 'LogisticContractModal',
  initialState,
  reducers: {
    setActions(
      state,
      action: PayloadAction<{
        create: (data: any) => void;
        delete: (data: any) => void;
        checkConnection: (data: any) => void;
      }>,
    ) {
      state.actions = action.payload;
    },
  },
});

export const logisticContractModalActions = logisticContractModalSlice.actions;
export const logisticContractModalReducer = logisticContractModalSlice.reducer;

export type LogisticContractModalCombineState = {
  logisticContractModal: LogisticContractModalState;
  deutschePostInternetmarkeContract: DeutschePostInternetmarkeContractState;
  logisticContract: LogisticContractState;
};

export const logisticContractModalCombineReducer = combineReducers<LogisticContractModalCombineState>({
  logisticContractModal: logisticContractModalReducer,
  deutschePostInternetmarkeContract: deutschePostInternetmarkeContractReducer,
  logisticContract: logisticContractReducer,
});
