import { ClassNameProps, DataProps } from '@/Types';
import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Label.scss';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { RefDocsIcon } from '@/Static';

type Props<TFormData extends object> = {
  text: string;
  labelTextClassName?: string;
  reverse?: boolean;
  nowrap?: boolean;
  alignRight?: boolean;
  onClick?: () => void;
  htmlFor?: keyof TFormData;
  disabled?: boolean;
  dataAttributes?: DataProps;
  hint?: string;
  id?: string;
} & ClassNameProps;

export function Label<TFormData extends object>({
  className,
  text,
  children,
  labelTextClassName,
  reverse = false,
  nowrap = false,
  onClick,
  alignRight = false,
  htmlFor,
  disabled = false,
  dataAttributes,
  hint,
  id,
}: PropsWithChildren<Props<TFormData>>) {
  const hintRender = () => {
    return (
      hint && (
        <span className={styles.hintWrapper}>
          <Tooltip className={styles.message} text={hint} shouldShowOnHover={true}>
            <RefDocsIcon />
          </Tooltip>
        </span>
      )
    );
  };

  return (
    <label
      id={id}
      htmlFor={(htmlFor as string) || undefined}
      className={cn(className, styles.label, { [styles.alignRight]: alignRight, [styles.icon_disabled]: disabled })}
      onClick={() => onClick?.()}
      {...dataAttributes}
    >
      {reverse ? (
        <>
          {children}
          <span className={cn(labelTextClassName, styles.label_text, { [styles.nowrap]: nowrap })}>
            {text} {hintRender()}
          </span>
        </>
      ) : (
        <>
          <span className={cn(labelTextClassName, styles.label_text, { [styles.nowrap]: nowrap })}>
            {text} {hintRender()}
          </span>
          {children}
        </>
      )}
    </label>
  );
}
