import { MpAccountModel, MpAccountShortModel } from '@/Models';

function toShortModel(mpAccount: MpAccountModel): MpAccountShortModel {
  return {
    id: mpAccount.id,
    marketplaceType: mpAccount.marketplaceType,
    name: mpAccount.accountName,
    isActive: mpAccount.isActive,
  };
}

export const mpAccountMapping = {
  toShortModel,
};
