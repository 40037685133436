export enum CountryType {
  'Afghanistan' = 'Afghanistan',
  'AlandIslands' = 'AlandIslands',
  'Albania' = 'Albania',
  'Algeria' = 'Algeria',
  'AmericanSamoa' = 'AmericanSamoa',
  'Andorra' = 'Andorra',
  'Angola' = 'Angola',
  'Anguilla' = 'Anguilla',
  'Antarctica' = 'Antarctica',
  'AntiguaAndBarbuda' = 'AntiguaAndBarbuda',
  'Argentina' = 'Argentina',
  'Armenia' = 'Armenia',
  'Aruba' = 'Aruba',
  'Australia' = 'Australia',
  'Austria' = 'Austria',
  'Azerbaijan' = 'Azerbaijan',
  'Bahamas' = 'Bahamas',
  'Bahrain' = 'Bahrain',
  'Bangladesh' = 'Bangladesh',
  'Barbados' = 'Barbados',
  'Belarus' = 'Belarus',
  'Belgium' = 'Belgium',
  'Belize' = 'Belize',
  'Benin' = 'Benin',
  'Bermuda' = 'Bermuda',
  'Bhutan' = 'Bhutan',
  'Bolivia' = 'Bolivia',
  'BonaireSintEustatiusAndSaba' = 'BonaireSintEustatiusAndSaba',
  'BosniaAndHerzegovina' = 'BosniaAndHerzegovina',
  'Botswana' = 'Botswana',
  'BouvetIsland' = 'BouvetIsland',
  'Brazil' = 'Brazil',
  'BritishIndianOceanTerritory' = 'BritishIndianOceanTerritory',
  'Brunei' = 'Brunei',
  'Bulgaria' = 'Bulgaria',
  'BurkinaFaso' = 'BurkinaFaso',
  'BurmaMyanmar' = 'BurmaMyanmar',
  'Burundi' = 'Burundi',
  'Cambodia' = 'Cambodia',
  'Cameroon' = 'Cameroon',
  'Canada' = 'Canada',
  'CapeVerde' = 'CapeVerde',
  'CaymanIslands' = 'CaymanIslands',
  'CentralAfricanRepublic' = 'CentralAfricanRepublic',
  'Chad' = 'Chad',
  'Chile' = 'Chile',
  'China' = 'China',
  'ChristmasIsland' = 'ChristmasIsland',
  'CocosKeelingIslands' = 'CocosKeelingIslands',
  'Colombia' = 'Colombia',
  'Comoros' = 'Comoros',
  'CongoDemRepublic' = 'CongoDemRepublic',
  'CongoRepublic' = 'CongoRepublic',
  'CookIslands' = 'CookIslands',
  'CostaRica' = 'CostaRica',
  'Croatia' = 'Croatia',
  'Cuba' = 'Cuba',
  'Curacao' = 'Curacao',
  'Cyprus' = 'Cyprus',
  'CzechRepublic' = 'CzechRepublic',
  'Denmark' = 'Denmark',
  'Djibouti' = 'Djibouti',
  'Dominica' = 'Dominica',
  'DominicanRepublic' = 'DominicanRepublic',
  'EastTimor' = 'EastTimor',
  'Ecuador' = 'Ecuador',
  'Egypt' = 'Egypt',
  'ElSalvador' = 'ElSalvador',
  'EquatorialGuinea' = 'EquatorialGuinea',
  'Eritrea' = 'Eritrea',
  'Estonia' = 'Estonia',
  'Ethiopia' = 'Ethiopia',
  'FalklandIslands' = 'FalklandIslands',
  'FaroeIslands' = 'FaroeIslands',
  'Fiji' = 'Fiji',
  'Finland' = 'Finland',
  'France' = 'France',
  'FrenchGuiana' = 'FrenchGuiana',
  'FrenchPolynesia' = 'FrenchPolynesia',
  'FrenchSouthernTerritories' = 'FrenchSouthernTerritories',
  'Gabon' = 'Gabon',
  'Gambia' = 'Gambia',
  'Georgia' = 'Georgia',
  'Germany' = 'Germany',
  'Ghana' = 'Ghana',
  'Gibraltar' = 'Gibraltar',
  'Greece' = 'Greece',
  'Greenland' = 'Greenland',
  'Grenada' = 'Grenada',
  'Guadeloupe' = 'Guadeloupe',
  'Guam' = 'Guam',
  'Guatemala' = 'Guatemala',
  'Guernsey' = 'Guernsey',
  'Guinea' = 'Guinea',
  'GuineaBissau' = 'GuineaBissau',
  'Guyana' = 'Guyana',
  'Haiti' = 'Haiti',
  'HeardIslandAndMcDonaldIslands' = 'HeardIslandAndMcDonaldIslands',
  'Honduras' = 'Honduras',
  'HongKong' = 'HongKong',
  'Hungary' = 'Hungary',
  'Iceland' = 'Iceland',
  'India' = 'India',
  'Indonesia' = 'Indonesia',
  'Iran' = 'Iran',
  'Iraq' = 'Iraq',
  'Ireland' = 'Ireland',
  'Israel' = 'Israel',
  'Italy' = 'Italy',
  'IvoryCoast' = 'IvoryCoast',
  'Jamaica' = 'Jamaica',
  'Japan' = 'Japan',
  'Jersey' = 'Jersey',
  'Jordan' = 'Jordan',
  'Kazakhstan' = 'Kazakhstan',
  'Kenya' = 'Kenya',
  'Kiribati' = 'Kiribati',
  'KoreaDemRepublicOf' = 'KoreaDemRepublicOf',
  'Kuwait' = 'Kuwait',
  'Kyrgyzstan' = 'Kyrgyzstan',
  'Laos' = 'Laos',
  'Latvia' = 'Latvia',
  'Lebanon' = 'Lebanon',
  'Lesotho' = 'Lesotho',
  'Liberia' = 'Liberia',
  'Libya' = 'Libya',
  'Liechtenstein' = 'Liechtenstein',
  'Lithuania' = 'Lithuania',
  'Luxemburg' = 'Luxemburg',
  'Macau' = 'Macau',
  'Macedonia' = 'Macedonia',
  'Madagascar' = 'Madagascar',
  'Malawi' = 'Malawi',
  'Malaysia' = 'Malaysia',
  'Maldives' = 'Maldives',
  'Mali' = 'Mali',
  'Malta' = 'Malta',
  'ManIsland' = 'ManIsland',
  'MarshallIslands' = 'MarshallIslands',
  'Martinique' = 'Martinique',
  'Mauritania' = 'Mauritania',
  'Mauritius' = 'Mauritius',
  'Mayotte' = 'Mayotte',
  'Mexico' = 'Mexico',
  'Micronesia' = 'Micronesia',
  'Moldova' = 'Moldova',
  'Monaco' = 'Monaco',
  'Mongolia' = 'Mongolia',
  'Montenegro' = 'Montenegro',
  'Montserrat' = 'Montserrat',
  'Morocco' = 'Morocco',
  'Mozambique' = 'Mozambique',
  'Namibia' = 'Namibia',
  'Nauru' = 'Nauru',
  'Nepal' = 'Nepal',
  'Netherlands' = 'Netherlands',
  'NetherlandsAntilles' = 'NetherlandsAntilles',
  'NewCaledonia' = 'NewCaledonia',
  'NewZealand' = 'NewZealand',
  'Nicaragua' = 'Nicaragua',
  'Niger' = 'Niger',
  'Nigeria' = 'Nigeria',
  'Niue' = 'Niue',
  'NorfolkIsland' = 'NorfolkIsland',
  'NorthernMarianaIslands' = 'NorthernMarianaIslands',
  'Norway' = 'Norway',
  'Oman' = 'Oman',
  'Pakistan' = 'Pakistan',
  'Palau' = 'Palau',
  'PalestinianTerritories' = 'PalestinianTerritories',
  'Panama' = 'Panama',
  'PapuaNewGuinea' = 'PapuaNewGuinea',
  'Paraguay' = 'Paraguay',
  'Peru' = 'Peru',
  'Philippines' = 'Philippines',
  'Pitcairn' = 'Pitcairn',
  'Poland' = 'Poland',
  'Portugal' = 'Portugal',
  'PuertoRico' = 'PuertoRico',
  'Qatar' = 'Qatar',
  'ReunionIsland' = 'ReunionIsland',
  'Romania' = 'Romania',
  'RussianFederation' = 'RussianFederation',
  'Rwanda' = 'Rwanda',
  'SaintBarthelemy' = 'SaintBarthelemy',
  'SaintHelenaAscensionAndTristan' = 'SaintHelenaAscensionAndTristan',
  'SaintKittsAndNevis' = 'SaintKittsAndNevis',
  'SaintLucia' = 'SaintLucia',
  'SaintMartin' = 'SaintMartin',
  'SaintPierreAndMiquelon' = 'SaintPierreAndMiquelon',
  'SaintVincentAndTheGrenadines' = 'SaintVincentAndTheGrenadines',
  'Samoa' = 'Samoa',
  'SanMarino' = 'SanMarino',
  'SaoTomeAndPrincipe' = 'SaoTomeAndPrincipe',
  'SaudiArabia' = 'SaudiArabia',
  'Senegal' = 'Senegal',
  'Serbia' = 'Serbia',
  'Seychelles' = 'Seychelles',
  'SierraLeone' = 'SierraLeone',
  'Singapore' = 'Singapore',
  'SintMaarten' = 'SintMaarten',
  'Slovakia' = 'Slovakia',
  'Slovenia' = 'Slovenia',
  'SolomonIslands' = 'SolomonIslands',
  'Somalia' = 'Somalia',
  'SouthAfrica' = 'SouthAfrica',
  'SouthGeorgiaAndTheSouthSandwichIslands' = 'SouthGeorgiaAndTheSouthSandwichIslands',
  'SouthKorea' = 'SouthKorea',
  'SouthSudan' = 'SouthSudan',
  'Spain' = 'Spain',
  'SriLanka' = 'SriLanka',
  'Sudan' = 'Sudan',
  'Suriname' = 'Suriname',
  'SvalbardAndJanMayen' = 'SvalbardAndJanMayen',
  'Swaziland' = 'Swaziland',
  'Sweden' = 'Sweden',
  'Switzerland' = 'Switzerland',
  'Syria' = 'Syria',
  'Taiwan' = 'Taiwan',
  'Tajikistan' = 'Tajikistan',
  'Tanzania' = 'Tanzania',
  'Thailand' = 'Thailand',
  'Togo' = 'Togo',
  'Tokelau' = 'Tokelau',
  'Tonga' = 'Tonga',
  'TrinidadAndTobago' = 'TrinidadAndTobago',
  'Tunisia' = 'Tunisia',
  'Turkey' = 'Turkey',
  'Turkmenistan' = 'Turkmenistan',
  'TurksAndCaicosIslands' = 'TurksAndCaicosIslands',
  'Tuvalu' = 'Tuvalu',
  'Uganda' = 'Uganda',
  'Ukraine' = 'Ukraine',
  'UnitedArabEmirates' = 'UnitedArabEmirates',
  'UnitedKingdom' = 'UnitedKingdom',
  'UnitedStates' = 'UnitedStates',
  'UnitedStatesMinorOutlyingIslands' = 'UnitedStatesMinorOutlyingIslands',
  'Uruguay' = 'Uruguay',
  'Uzbekistan' = 'Uzbekistan',
  'Vanuatu' = 'Vanuatu',
  'VaticanCityState' = 'VaticanCityState',
  'Venezuela' = 'Venezuela',
  'Vietnam' = 'Vietnam',
  'VirginIslandsBritish' = 'VirginIslandsBritish',
  'VirginIslandsUS' = 'VirginIslandsUS',
  'WallisAndFutuna' = 'WallisAndFutuna',
  'WesternSahara' = 'WesternSahara',
  'Yemen' = 'Yemen',
  'Zambia' = 'Zambia',
  'Zimbabwe' = 'Zimbabwe',
  'None' = 'None',
}
