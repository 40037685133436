import { ApiRoutingUtils } from '@/Routing';
import {
  CheckLogisticContractRequest,
  CheckLogisticContractResponse,
  CreateLogisticContractRequest,
  CreateLogisticContractResponse,
  GetAllLogisticContractResponse,
  UpdateLogisticContractRequest,
  UpdateLogisticContractResponse,
} from '../LogisticContracts';
import { rest } from '@/Api/Rest';
import { GetActiveLogisticTypesResponse } from './GetActiveLogisticTypesResponse';

const url = ApiRoutingUtils.createUrl('logistic-contracts');

async function getAllLogisticContracts(): Promise<GetAllLogisticContractResponse> {
  return rest.get(`${url}`);
}

async function createLogisticContract(request: CreateLogisticContractRequest): Promise<CreateLogisticContractResponse> {
  return rest.post(url, request);
}

async function updateLogisticContract(request: UpdateLogisticContractRequest): Promise<UpdateLogisticContractResponse> {
  return rest.put(url, request);
}

async function deleteLogisticContract(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function checkConnection(request: CheckLogisticContractRequest): Promise<CheckLogisticContractResponse> {
  return rest.post(`${url}/check-connection`, request);
}

async function getActiveLogisticTypes(): Promise<GetActiveLogisticTypesResponse> {
  return rest.get(`${url}/active`);
}

export const LogisticContractsApi = {
  getAllLogisticContracts,
  createLogisticContract,
  updateLogisticContract,
  deleteLogisticContract,
  checkConnection,
  getActiveLogisticTypes,
};
