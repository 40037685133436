import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetSanctionCountriesResponse } from './GetSanctionCountriesResponse';

const url = ApiRoutingUtils.createUrl('countries');

async function getSanctionCountries(): Promise<GetSanctionCountriesResponse> {
  return rest.get(`${url}/get-sanction-countries`);
}

export const CountriesApi = {
  getSanctionCountries,
};
