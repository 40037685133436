import { useSelector } from 'react-redux';
import { userSelectors } from '@/Redux/User';
import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { treeViewUtils } from '@/Utils';
import { MarketplaceType } from '@/Enums';
import { useLogisticProductsTreeViewOptions } from './useLogisticProductsTreeView';
import { Pair } from '@/Types';

// Marketplace account tree view options
export function useMpAccountTreeViewOptions(
  selectedMpAccountIds?: (string | number)[],
  excludedMpAccounts: (string | MarketplaceType)[] = [],
  isAllMpAccountsSelected?: boolean,
  needShowActiveAccounts?: boolean,
) {
  const mpAccountOptions = useSelector(userSelectors.currentUserAccountTreeViewOptions);
  const mpActiveAccounts = useSelector(userSelectors.currentUserAccountActiveTreeViewOptions);
  const mpAccounts = needShowActiveAccounts ? mpActiveAccounts : mpAccountOptions;

  const [needToReset, setNeedToReset] = useState(false);

  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>(() => {
    const selectedKeys = treeViewUtils.getSelectedKeys(
      mpAccounts
        .filter((mpAccount) => !excludedMpAccounts.length || !excludedMpAccounts.some((a) => a === mpAccount.key))
        .map((mpAccount) => ({
          ...mpAccount,
          items: mpAccount.items?.map((item) =>
            isAllMpAccountsSelected || selectedMpAccountIds?.includes(item.key) ? { ...item, selected: true } : item,
          ),
        })),
    );

    return selectedKeys as string[];
  });

  const mpTreeViewItems = useMemo<TreeItemType<number | string>[]>(() => {
    setNeedToReset(false);

    return mpAccounts
      .filter((mpAccount) => !excludedMpAccounts.length || !excludedMpAccounts.some((a) => a === mpAccount.key))
      .map((mpAccount) => ({
        ...mpAccount,
        items: mpAccount.items?.map((item) =>
          isAllMpAccountsSelected || selectedMpAccountIds?.includes(item.key) ? { ...item, selected: true } : item,
        ),
      }));
  }, [mpAccounts, selectedMpAccountIds, needToReset]);

  const mpIds = useMemo(() => selectedAccountIds.filter((id) => !isNaN(+id)).map((id) => +id), [selectedAccountIds]);

  return {
    mpTreeViewItems,
    mpIds,
    setNeedToReset,
    setSelectedAccountIds,
  };
} // useMpAccountTreeViewOptions

// Marketplace account tree view options for form inputs
export function useMpAccountFormTreeViewOptions(defaultValues?: (string | number)[]) {
  const mpAccounts = useSelector(userSelectors.currentUserAccountTreeViewOptions);
  const [needToReset, setNeedToReset] = useState(false);

  const defaultMpAccountIds = defaultValues;

  const mpTreeViewItems = useMemo<TreeItemType<string | number>[]>(() => {
    setNeedToReset(false);

    return mpAccounts
      .map((mpAccount) => ({
        ...mpAccount,
        items: mpAccount.items
          ?.map((item) =>
            defaultMpAccountIds?.includes(item.key) ? { ...item, selected: true } : { ...item, selected: false },
          )
          ?.sort((a, b) => a.value.localeCompare(b.value)),
      }))
      ?.sort((a, b) => a.value.localeCompare(b.value));
  }, [mpAccounts, needToReset]);

  const { logisticProductsViewItems } = useLogisticProductsTreeViewOptions();

  return {
    mpTreeViewItems,
    setNeedToReset,
    logisticProductsViewItems,
  };
} // useMpAccountFormTreeViewOptions

// Marketplace account pairs
export function useMpAccountPairOptions(includedMpTypes: MarketplaceType[] = []) {
  const mpAccounts = useSelector(userSelectors.currentUserMpAccounts);

  const mpAccountOptions: Pair<number>[] = useMemo<Pair<number>[]>(() => {
    return mpAccounts
      .filter((x) => !includedMpTypes.length || includedMpTypes.includes(x.marketplaceType))
      .map((account) => ({
        key: account.id,
        value: account.name,
      }));
  }, [mpAccounts]);

  return mpAccountOptions;
}
