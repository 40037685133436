import { AccountChartModel, AccountChartTypeModel } from '@/Models/_Microservices/OnlineArchive/AccountChartModel';
import { AccountChartSettingModel } from '@/Models/_Microservices/OnlineArchive/AccountChartSettingModel';
import { ModalDataType } from '@/Types/ModalDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type AccountChartsDataState = {
  accountChartsInfo: {
    accountCharts: AccountChartModel[];
    usedAccountCharts: AccountChartModel[];
    accountChartType: AccountChartTypeModel | null;
  };
  selectedAccountChart: AccountChartModel | null;
  accountChartModalData: ModalDataType<{
    accountChartModel?: AccountChartModel;
    accountChartType: AccountChartTypeModel;
  }>;
  accountChartSettingModalData: {
    data: {
      accountChartSetting: AccountChartSettingModel | null;
      availableAccountChartTypes: AccountChartTypeModel[];
      accountChartsPreviewModalData: ModalDataType<{
        accountCharts: AccountChartModel[];
        accountChartTypes: AccountChartTypeModel[];
      }>;
      confirmDialogData: ModalDataType<AccountChartSettingModel>;
    };
    visible: boolean;
  };
};

const initialState: AccountChartsDataState = {
  accountChartsInfo: {
    accountCharts: [],
    usedAccountCharts: [],
    accountChartType: null,
  },
  selectedAccountChart: null,
  accountChartSettingModalData: {
    data: {
      accountChartSetting: null,
      availableAccountChartTypes: [],
      accountChartsPreviewModalData: {
        visible: false,
      },
      confirmDialogData: {
        visible: false,
      },
    },
    visible: false,
  },
  accountChartModalData: {
    visible: true,
  },
};

export const accountChartsDataSlice = createSlice({
  name: 'accountChartsData',
  initialState: initialState,
  reducers: {
    setSelectedAccountChart: (state, action: PayloadAction<AccountChartModel | null>) => {
      state.selectedAccountChart = action.payload;
    },
    showAccountChartModal: (
      state,
      action: PayloadAction<{
        accountChart?: AccountChartModel;
        accountChartType: AccountChartTypeModel;
      }>,
    ) => {
      state.accountChartModalData = {
        visible: true,
        data: action.payload,
      };
    },
    closeAccountChartModal: (state) => {
      state.accountChartModalData = {
        visible: false,
      };
    },
    showAccountChartSettingModal: (state) => {
      state.accountChartSettingModalData.visible = true;
    },
    closeAccountChartSettingModal: (state) => {
      state.accountChartSettingModalData.visible = false;
    },
    showAccountChartSettingConfirmDialog: (state, action: PayloadAction<AccountChartSettingModel>) => {
      state.accountChartSettingModalData.data.confirmDialogData = {
        visible: true,
        data: action.payload,
      };
    },
    closeAccountChartSettingConfirmDialog: (state) => {
      state.accountChartSettingModalData.data.confirmDialogData = {
        visible: false,
      };
    },
    closeAccountChartsPreviewModal: (state) => {
      state.accountChartSettingModalData.data.accountChartsPreviewModalData = {
        visible: false,
      };
    },
    clearAccountCharts: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getAccountCharts.fulfilled, (state, action) => {
        const accountChartSetting = action.payload.accountChartSetting;

        state.accountChartSettingModalData = {
          visible: false,
          data: {
            accountChartSetting: accountChartSetting ?? null,
            availableAccountChartTypes: [],
            accountChartsPreviewModalData: {
              visible: false,
            },
            confirmDialogData: {
              visible: false,
            },
          },
        };

        state.accountChartsInfo = {
          accountCharts: action.payload.accountCharts,
          usedAccountCharts: action.payload.usedAccountCharts,
          accountChartType: action.payload.accountChartType ?? null,
        };
      })
      .addCase(asyncActions.getAvailableAccountChartTypes.fulfilled, (state, action) => {
        state.accountChartSettingModalData.data.availableAccountChartTypes = action.payload.accountChartTypes;
      })
      .addCase(asyncActions.setAccountChartSetting.fulfilled, (state, action) => {
        state.accountChartSettingModalData = {
          visible: false,
          data: {
            accountChartSetting: action.payload.accountChartSetting,
            availableAccountChartTypes: [],
            accountChartsPreviewModalData: {
              visible: false,
            },
            confirmDialogData: {
              visible: false,
            },
          },
        };

        state.accountChartsInfo = {
          ...state.accountChartsInfo,
          accountCharts: action.payload.accountCharts,
          accountChartType: action.payload.accountChartType,
        };
      })
      .addCase(asyncActions.getAccountChartsForPreview.fulfilled, (state, action) => {
        if (state.accountChartSettingModalData.data) {
          state.accountChartSettingModalData.data.accountChartsPreviewModalData = {
            visible: true,
            data: {
              accountCharts: action.payload.accountCharts,
              accountChartTypes: action.payload.accountChartTypes,
            },
          };
        } // if
      })
      .addCase(asyncActions.createAccountChart.fulfilled, (state, action) => {
        state.accountChartsInfo.accountCharts.push(action.payload.accountChart);
      })
      .addCase(asyncActions.updateAccountChart.fulfilled, (state, action) => {
        state.accountChartsInfo.accountCharts = state.accountChartsInfo.accountCharts.map((a) => {
          return a.id === action.payload.accountChart.id ? action.payload.accountChart : a;
        });

        state.selectedAccountChart = action.payload.accountChart;
      })
      .addCase(asyncActions.deleteAccountChart.fulfilled, (state, action) => {
        state.accountChartsInfo.accountCharts = state.accountChartsInfo.accountCharts.filter((a) => {
          return a.id !== action.payload.id;
        });
      });
  },
});

export const accountChartsDataActions = accountChartsDataSlice.actions;
export const accountChartsDataReducer = accountChartsDataSlice.reducer;
export const accountChartsDataAsyncActions = asyncActions;
