export class PagesRouting {
  public static MainPages = {
    DashboardPage: {
      Dashboard: '/dashboard',
    },
    SalesPage: {
      Sales: '/sales',
      SalesSettings: '/sales/settings',
    },
    PurchasesPage: '/purchases',
    LogisticsPage: {
      Logistics: '/logistics',
      LogisticsSettings: '/logistics/settings',
    },
    ProductsPage: {
      Products: '/products',
      ProductsSettings: '/products/settings',
    },
    WarehousePage: {
      Warehouse: '/warehouse',
      WarehouseSettings: '/warehouse/settings',
    },
    MessagesPage: '/messages',
    SuppliersPage: {
      Suppliers: '/suppliers',
      SuppliersSettings: '/suppliers/settings',
    },
    CustomersPage: {
      Customers: '/customers',
      CustomersSettings: '/customers/settings',
    },
    ListingPage: '/listing',
    DesignServicePage: '/design-service',
    AccountingPage: {
      Accounting: '/accounting',
      AccountingSettings: '/accounting/settings',
    },
    OnlineArchivePage: {
      OnlineArchive: '/online-archive',
      OnlineArchiveSettings: '/online-archive/settings',
      UploadTab: '/online-archive/upload',
      AllocateTab: '/online-archive/allocate',
      ArchiveTab: '/online-archive/archive',
      ReportsTab: '/online-archive/reports',
    },
    ShopPage: {
      Shop: '/shop',
      ShopSettings: '/shop/settings',
    },
    PermissionDenied: '/permission-denied',
    PaymentsPage: {
      Payments: '/payments',
      SuccessPayment: '/payments/success',
      ErrorPayment: '/payments/error',
    },
  };

  public static AuthorizationPages = {
    LoginPage: '/login',
    RegistrationPage: '/registration',
    EmailConfirmationPage: '/email-confirmation',
    ResetPasswordPage: '/reset-password',
    ChangePasswordPage: '/change-password',
  };

  public static SettingsPages = {
    Settings: '/settings',
    AccountSettings: '/settings/account',
    SynchronizeSettings: '/settings/synchronize',
    MandatsSettings: '/settings/mandats',
    OrderAutomationSettings: '/settings/order-automation',
    LogisticSettings: '/settings/logistic',
    ShippingAutomationSettings: {
      AmountAutomation: '/settings/shipping-automation',
      CategoryAutomation: '/settings/shipping-automation/category',
      PackagingAutomation: '/settings/shipping-automation/packaging',
      MixCategoryAutomation: '/settings/shipping-automation/mix-category',
    },
    AddressBookSettings: '/settings/address-book',
    PackagesSettings: '/settings/packages',
    MarketplacesSettings: '/settings/marketplaces',
    OnlineArchiveSetting: {
      General: '/settings/online-archive',
      DocumentCategories: '/settings/online-archive/document-categories',
      AllocateCategories: '/settings/online-archive/allocate-categories',
      UploadTemplates: '/settings/online-archive/upload-templates',
      AccountChart: '/settings/online-archive/account-chart',
      ScanDocuments: '/settings/online-archive/scan-documents',
    },
    ProductsSettings: {
      Categories: '/settings/products',
    },
    InvoicesSettings: '/settings/invoices',
    FeedbacksSettings: '/settings/feedbacks',
    GridsColorsSettings: '/settings/grids-colors',
    IntegrationsSettings: '/settings/integrations',
    ShipCheaperApiSettings: '/settings/ship-cheaper-api',
    HelpSettings: '/settings/help',
    InfoSettings: '/settings/info',
    DocumentTemplateSettings: '/settings/document-templates',
    EmailTemplateSettings: '/settings/email-templates',
    WarehouseSettings: '/settings/warehouse',
  };

  public static AdminPages = {
    Admin: '/admin',
    AdminProductsPage: '/admin/products',
    AdminWarehousePage: '/admin/warehouse',
    AdminCustomersPage: '/admin/customers',
    AdminWarehouseLogisticsPage: '/admin/warehouse-logistics',
    AdminLanguageEditorPage: {
      LanguageEditor: '/admin/language-editor',
      ErpTranslations: '/admin/language-editor/erp-translations',
      WebsiteTranslations: '/admin/language-editor/website-translations',
      ProductTranslations: '/admin/language-editor/product-translations',
      EditorSettings: '/admin/language-editor/editor-settings',
    },
    AdminAccounting: '/admin/accounting',
    AdminPermissionDenied: '/admin/permission-denied',
    AdminDigitalProductsPage: '/admin/digital-products',
    AdminSubscriptionsPage: '/admin/subscriptions',
    AdminCalendarPage: {
      Calendar: '/admin/calendar',
      Holidays: '/admin/calendar/holidays',
    },
    AdminBillingPage: '/admin/billing',
  };

  public static ProfilePages = {
    BillingPage: '/profile/billing',
    PaymentsPage: '/profile/payments',
    SubscriptionsPage: '/profile/subscriptions',
  };
}
