import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';
import { CountryCode } from '@/Enums';
import { CountryVatInfoModel } from '@/Models/Admin/CountryVat/CountryVatInfoModel';
import { Nullable } from '@/Types';
import { CountryVatModel } from '@/Models/Admin/CountryVat/CountryVatModel';

export type VatRatesState = {
  selectedCountryCode: CountryCode | null;
  countryVatInfos: CountryVatInfoModel[];
  vatFormData: Nullable<CountryVatModel>;
};

const initialState: VatRatesState = {
  selectedCountryCode: null,
  countryVatInfos: [],
  vatFormData: null,
};

const vatRatesSlice = createSlice({
  name: 'vatRates',
  initialState: initialState,
  reducers: {
    setSelectedCountryCode: (state, action: PayloadAction<CountryCode>) => {
      state.selectedCountryCode = action.payload;

      if (state.selectedCountryCode != action.payload) {
        state.vatFormData = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getCountryVatInfos.fulfilled, (state, action) => {
        state.countryVatInfos = action.payload.countryVatInfos;
      })
      .addCase(asyncActions.getVatInfoByCountry.fulfilled, (state, action) => {
        state.vatFormData = action.payload.countryVat;
      });
  },
});

export const vatRatesReducer = vatRatesSlice.reducer;
export const vatRatesActions = vatRatesSlice.actions;
export const vatRatesAsyncActions = asyncActions;
