import { NumberFormatHandler } from '@/Utils/services/NumberFormatHandler';

/* tslint:disable */
declare global {
  interface Number {
    toDecimalString(locales?: string | string[]): string;
    toIntegerString(locales?: string | string[]): string;
    toCurrencyString(locales?: string | string[]): string;
    toWeightString(locales?: string | string[]): string;
    toVatString(locales?: string | string[]): string;
    toPriceString(locales?: string | string[]): string;
  }
}

Number.prototype.toDecimalString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    maximumFractionDigits: 10,
  });
};

Number.prototype.toIntegerString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    maximumFractionDigits: 0,
  });
};

Number.prototype.toVatString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
};

Number.prototype.toCurrencyString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

Number.prototype.toWeightString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

Number.prototype.toPriceString = function (locales?: string | string[]) {
  return Number(this).toLocaleString(locales ?? NumberFormatHandler.getInstance().getNumberFormatLocales(), {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
};

export {};
