import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useMixCategoryAutomationSettings() {
  const {
    tours: {
      ShippingAutomationSettings: { Mix },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{Mix.Step1.Text}</div>,
      placement: 'auto',
      target: '#mixCategoryAutomation',
      title: Mix.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    defaultTour,
  };
}
