import React, { useState } from 'react';
import { FormProvider } from '@/Hooks/useFormWrapper';
import { useLocalization } from '@/Hooks';
import { ModalProps } from '@/Types';
import styles from './OrderAutomatizationModal.scss';
import { PagesRouting } from '@/Routing';
import { OrderAutomatizaionTableMemo } from './modules/OrderAutomatizationTable/OrderAutomatizationTable';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Link } from '@/Components/Controls/Link/Link';
import { Modal } from '@/Components/Modal/Modal';
import { useOrderAutomatizationModalForm } from './hooks/useOrderAutomatizationModalForm';
import { useOrderAutomatizationModalOptions } from './hooks/useOrderAutomatizationModalOptions';
import { useOrderAutomatizationModalLoader } from './hooks/useOrderAutomatizationModalLoader';

type Props = {
  isSales: boolean;
} & ModalProps;

export function OrderAutomatizaionModal({ isSales, closeModalWindow }: Props) {
  const {
    modalWindows: {
      orderLinkWithProduct: { Description, Buttons },
    },
  } = useLocalization();

  const [isSaveMixAutomationRules, setIsSaveMixAutomationRules] = useState(false);

  const {
    editableOrderIds,
    orderAutomatizationRows,
    orderPackages,
    selectedOrders,
  } = useOrderAutomatizationModalOptions(isSales);

  const { methods, onSubmit } = useOrderAutomatizationModalForm(
    orderAutomatizationRows,
    orderPackages,
    isSaveMixAutomationRules,
  );

  useOrderAutomatizationModalLoader(editableOrderIds, selectedOrders);

  if (orderAutomatizationRows.length == 0) {
    return null;
  } // if

  return (
    <Modal modalClassName={styles.modal} onClose={closeModalWindow} shouldCloseOnOverlayClick={false}>
      <div className={styles.modalDescription}>
        <h4>{Description.Title}</h4>
        <p>{Description.Text}</p>
      </div>
      <FormProvider {...methods}>
        <form className={styles.formWrapper} onSubmit={onSubmit}>
          <OrderAutomatizaionTableMemo />
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonsLeft}>
              <Link to={PagesRouting.SettingsPages.ShippingAutomationSettings.AmountAutomation}>
                <Button text={Buttons.PackageAutomationRules} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} />
              </Link>
            </div>

            <div className={styles.buttonsRight}>
              <Button text={Buttons.Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={closeModalWindow} />

              <Button
                text={Buttons.SavePackages}
                theme={BUTTON_THEMES.INFO_LIGHT}
                type={'submit'}
                onClick={() => setIsSaveMixAutomationRules(false)}
              />

              <Button
                text={Buttons.SavePackagesTemplates}
                theme={BUTTON_THEMES.SUCCESS}
                type={'submit'}
                onClick={() => setIsSaveMixAutomationRules(true)}
                //disabled={isPackageRequired}
                disabled={true}
                className={styles.sendRules}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
