import React from 'react';
import cn from 'classnames';
import { useFormSetValue, useFormError } from '@/Hooks';
import { ClassNameProps, DataProps, DisabledProps, InputRefProps } from '@/Types';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './Radio.scss';

type Props<TFormData extends object> = {
  value?: string | number;
  asButton?: boolean;
  labelText?: string;
  dataAttributes?: DataProps;
} & ClassNameProps &
  DisabledProps &
  InputRefProps<HTMLInputElement, TFormData, string | number>;

export function FormRadio<TFormData extends object>({
  name,
  register,
  rules,
  setValue,
  value,
  valueForSet = '',
  labelText = '',
  error,
  disabled = false,
  asButton,
  className,
  dataAttributes,
}: Props<TFormData>) {
  useFormSetValue(name, valueForSet, setValue);
  const errorMessage = useFormError(name, error);

  return (
    <ErrorTooltip error={errorMessage} className={className}>
      <label className={styles.inputContainer}>
        <input
          name={(name as unknown) as string}
          {...register?.(name as any, rules)}
          value={value}
          readOnly={disabled}
          disabled={disabled}
          hidden={asButton}
          type={'radio'}
          className={cn(styles.input, { [errorTooltipClass]: errorMessage, [styles.error]: errorMessage })}
          {...dataAttributes}
        />
        <span className={cn(styles.labelText, { [styles.labelAsButton]: asButton })}>{labelText}</span>
      </label>
    </ErrorTooltip>
  );
}
