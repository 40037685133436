import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderAutomatizaionRow, Pair } from '@/Types';
import { orderMapping } from '@/Mapping';
import { CountryCode } from '@/Enums';
import { asyncActions } from './asyncActions';
import { OrderAutomatizationPackageModel } from '@/Models/OrderUserProducts/OrderAutomatizationPackageModel';

export type OrderAutomatizationModalState = {
  editableOrderIds: number[];
  orderAutomatizaionRows: OrderAutomatizaionRow[];
  orderPackages: {
    orderId: number;
    isOwnContract: boolean;
    packages: OrderAutomatizationPackageModel[];
  }[];
  countryCodeOptions: Pair<CountryCode>[];
};

const initialState: OrderAutomatizationModalState = {
  editableOrderIds: [],
  orderAutomatizaionRows: [],
  orderPackages: [],
  countryCodeOptions: [],
};

const orderAutomatizaionSlice = createSlice({
  name: 'orderAutomatization',
  initialState,
  reducers: {
    setSelectedOrders(state, action: PayloadAction<number[]>) {
      state.editableOrderIds = action.payload;
    },
    setCountryOptions(state, action: PayloadAction<Pair<CountryCode>[]>) {
      state.countryCodeOptions = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getOrdersAutomatizationRequest.fulfilled, (state, action) => {
        state.orderAutomatizaionRows = action.payload.orders.map((order) => {
          return orderMapping.getOrderAutomatizaionRow(order);
        });
      })
      .addCase(asyncActions.getPackagesForOrderRequest.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderPackages.push(action.payload);
        } // if
      })
      .addCase(asyncActions.getLogisticProducts.fulfilled, (state, action) => {
        const { orderId, packageId, orderUserProductModels } = action.payload;
        const orderIdx = state.orderPackages.findIndex((value) => value.orderId == orderId && !value.isOwnContract);
        const packageIdx = state.orderPackages[orderIdx].packages.findIndex((value) => value.packageId == packageId);

        state.orderPackages[orderIdx].packages[packageIdx].products = [...orderUserProductModels];
      });
  },
});

const { actions, reducer } = orderAutomatizaionSlice;

export const orderAutomatizationReducer = reducer;
export const orderAutomatizationActions = actions;
export const orderAutomatizationAsyncActions = asyncActions;
