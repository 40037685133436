import dxDataGrid from 'devextreme/ui/data_grid';

export const useOnContentReady = <TData>(
  onFocusedRowChanged?: (data: TData | null) => void,
  onSelectedRowsChanged?: (data: TData[]) => void,
  initSelectedRowKeys?: (string | number)[],
) => {
  const onContentReady = (e: { component?: dxDataGrid }) => {
    console.log('Table render');

    if (e.component) {
      if (!e.component.totalCount()) {
        onFocusedRowChanged?.(null);
        onSelectedRowsChanged?.([]);

        return;
      } // if

      if (initSelectedRowKeys) {
        e.component.selectRows(initSelectedRowKeys, false);
      }

      // Check selected rows visibility
      const visibleRows = e.component.getVisibleRows();
      const selectedRowKeys = e.component.getSelectedRowKeys();
      let isSelectedRowsVisible = false;

      for (const selectedRowKey of selectedRowKeys) {
        isSelectedRowsVisible = Boolean(visibleRows.find((row) => row.key === selectedRowKey));
        if (!isSelectedRowsVisible) break;
      }

      if (!isSelectedRowsVisible && visibleRows.length) {
        e.component.selectRowsByIndexes([0]);
      } // if
    } // if
  };

  return {
    onContentReady,
  };
};
