import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApplyAddressChangesRequest,
  ApplyAddressChangesResponse,
  LogisticsApi,
  OrderAddressValidationRequest,
  OrderAddressValidationResponse,
} from '@/Api/Logistics';
import { GetActiveLogisticTypesResponse } from '@/Api/LogisticContracts/GetActiveLogisticTypesResponse';
import { LogisticContractsApi } from '@/Api/LogisticContracts/LogisticContractsApi';

const validateOrderAddresses = createAsyncThunk<OrderAddressValidationResponse, OrderAddressValidationRequest>(
  'shippingLabelModal/validate',
  async (request: OrderAddressValidationRequest) => {
    return LogisticsApi.validateOrdersAddresses(request);
  },
);

const applyAddressChanges = createAsyncThunk<ApplyAddressChangesResponse, ApplyAddressChangesRequest>(
  'shippingLabelModal/apply-address-changes',
  async (request: ApplyAddressChangesRequest) => {
    return LogisticsApi.applyAddressChanges(request);
  },
);

const getActiveLogisticTypes = createAsyncThunk<GetActiveLogisticTypesResponse>(
  'orderAutomatization/get-active-logistic-types',
  async () => {
    return LogisticContractsApi.getActiveLogisticTypes();
  },
);

export const asyncActions = {
  validateOrderAddresses,
  applyAddressChanges,
  getActiveLogisticTypes,
};
