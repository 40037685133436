import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';
import { CountryCode } from '@/Enums/CountryCode';
import { CountryType } from '@/Enums/CountryType';
import { useSelector } from 'react-redux';
import { userSelectors } from '@/Redux/User';
import { TreeItemType } from '@/Components/TreeView/TreeView';

export function useCountryOptions(excludedCountries: CountryCode[] = []) {
  const {
    enums: { Countries },
  } = useLocalization();

  return useMemo(
    () =>
      [
        { key: CountryCode.AF, value: Countries[CountryType.Afghanistan] },
        { key: CountryCode.AX, value: Countries[CountryType.AlandIslands] },
        { key: CountryCode.AL, value: Countries[CountryType.Albania] },
        { key: CountryCode.DZ, value: Countries[CountryType.Algeria] },
        { key: CountryCode.AS, value: Countries[CountryType.AmericanSamoa] },
        { key: CountryCode.AD, value: Countries[CountryType.Andorra] },
        { key: CountryCode.AO, value: Countries[CountryType.Angola] },
        { key: CountryCode.AI, value: Countries[CountryType.Anguilla] },
        { key: CountryCode.AQ, value: Countries[CountryType.Antarctica] },
        { key: CountryCode.AG, value: Countries[CountryType.AntiguaAndBarbuda] },
        { key: CountryCode.AR, value: Countries[CountryType.Argentina] },
        { key: CountryCode.AM, value: Countries[CountryType.Armenia] },
        { key: CountryCode.AW, value: Countries[CountryType.Aruba] },
        { key: CountryCode.AU, value: Countries[CountryType.Australia] },
        { key: CountryCode.AT, value: Countries[CountryType.Austria] },
        { key: CountryCode.AZ, value: Countries[CountryType.Azerbaijan] },
        { key: CountryCode.BS, value: Countries[CountryType.Bahamas] },
        { key: CountryCode.BH, value: Countries[CountryType.Bahrain] },
        { key: CountryCode.BD, value: Countries[CountryType.Bangladesh] },
        { key: CountryCode.BB, value: Countries[CountryType.Barbados] },
        { key: CountryCode.BY, value: Countries[CountryType.Belarus] },
        { key: CountryCode.BE, value: Countries[CountryType.Belgium] },
        { key: CountryCode.BZ, value: Countries[CountryType.Belize] },
        { key: CountryCode.BJ, value: Countries[CountryType.Benin] },
        { key: CountryCode.BM, value: Countries[CountryType.Bermuda] },
        { key: CountryCode.BT, value: Countries[CountryType.Bhutan] },
        { key: CountryCode.BO, value: Countries[CountryType.Bolivia] },
        { key: CountryCode.BQ, value: Countries[CountryType.BonaireSintEustatiusAndSaba] },
        { key: CountryCode.BA, value: Countries[CountryType.BosniaAndHerzegovina] },
        { key: CountryCode.BW, value: Countries[CountryType.Botswana] },
        { key: CountryCode.BV, value: Countries[CountryType.BouvetIsland] },
        { key: CountryCode.BR, value: Countries[CountryType.Brazil] },
        { key: CountryCode.IO, value: Countries[CountryType.BritishIndianOceanTerritory] },
        { key: CountryCode.BN, value: Countries[CountryType.Brunei] },
        { key: CountryCode.BG, value: Countries[CountryType.Bulgaria] },
        { key: CountryCode.BF, value: Countries[CountryType.BurkinaFaso] },
        { key: CountryCode.MM, value: Countries[CountryType.BurmaMyanmar] },
        { key: CountryCode.BI, value: Countries[CountryType.Burundi] },
        { key: CountryCode.KH, value: Countries[CountryType.Cambodia] },
        { key: CountryCode.CM, value: Countries[CountryType.Cameroon] },
        { key: CountryCode.CA, value: Countries[CountryType.Canada] },
        { key: CountryCode.CV, value: Countries[CountryType.CapeVerde] },
        { key: CountryCode.KY, value: Countries[CountryType.CaymanIslands] },
        { key: CountryCode.CF, value: Countries[CountryType.CentralAfricanRepublic] },
        { key: CountryCode.TD, value: Countries[CountryType.Chad] },
        { key: CountryCode.CL, value: Countries[CountryType.Chile] },
        { key: CountryCode.CN, value: Countries[CountryType.China] },
        { key: CountryCode.CX, value: Countries[CountryType.ChristmasIsland] },
        { key: CountryCode.CC, value: Countries[CountryType.CocosKeelingIslands] },
        { key: CountryCode.CO, value: Countries[CountryType.Colombia] },
        { key: CountryCode.KM, value: Countries[CountryType.Comoros] },
        { key: CountryCode.CD, value: Countries[CountryType.CongoDemRepublic] },
        { key: CountryCode.CG, value: Countries[CountryType.CongoRepublic] },
        { key: CountryCode.CK, value: Countries[CountryType.CookIslands] },
        { key: CountryCode.CR, value: Countries[CountryType.CostaRica] },
        { key: CountryCode.HR, value: Countries[CountryType.Croatia] },
        { key: CountryCode.CU, value: Countries[CountryType.Cuba] },
        { key: CountryCode.CW, value: Countries[CountryType.Curacao] },
        { key: CountryCode.CY, value: Countries[CountryType.Cyprus] },
        { key: CountryCode.CZ, value: Countries[CountryType.CzechRepublic] },
        { key: CountryCode.DK, value: Countries[CountryType.Denmark] },
        { key: CountryCode.DJ, value: Countries[CountryType.Djibouti] },
        { key: CountryCode.DM, value: Countries[CountryType.Dominica] },
        { key: CountryCode.DO, value: Countries[CountryType.DominicanRepublic] },
        { key: CountryCode.TL, value: Countries[CountryType.EastTimor] },
        { key: CountryCode.EC, value: Countries[CountryType.Ecuador] },
        { key: CountryCode.EG, value: Countries[CountryType.Egypt] },
        { key: CountryCode.SV, value: Countries[CountryType.ElSalvador] },
        { key: CountryCode.GQ, value: Countries[CountryType.EquatorialGuinea] },
        { key: CountryCode.ER, value: Countries[CountryType.Eritrea] },
        { key: CountryCode.EE, value: Countries[CountryType.Estonia] },
        { key: CountryCode.ET, value: Countries[CountryType.Ethiopia] },
        { key: CountryCode.FK, value: Countries[CountryType.FalklandIslands] },
        { key: CountryCode.FO, value: Countries[CountryType.FaroeIslands] },
        { key: CountryCode.FJ, value: Countries[CountryType.Fiji] },
        { key: CountryCode.FI, value: Countries[CountryType.Finland] },
        { key: CountryCode.FR, value: Countries[CountryType.France] },
        { key: CountryCode.GF, value: Countries[CountryType.FrenchGuiana] },
        { key: CountryCode.PF, value: Countries[CountryType.FrenchPolynesia] },
        { key: CountryCode.TF, value: Countries[CountryType.FrenchSouthernTerritories] },
        { key: CountryCode.GA, value: Countries[CountryType.Gabon] },
        { key: CountryCode.GM, value: Countries[CountryType.Gambia] },
        { key: CountryCode.GE, value: Countries[CountryType.Georgia] },
        { key: CountryCode.DE, value: Countries[CountryType.Germany] },
        { key: CountryCode.GH, value: Countries[CountryType.Ghana] },
        { key: CountryCode.GI, value: Countries[CountryType.Gibraltar] },
        { key: CountryCode.GR, value: Countries[CountryType.Greece] },
        { key: CountryCode.GL, value: Countries[CountryType.Greenland] },
        { key: CountryCode.GD, value: Countries[CountryType.Grenada] },
        { key: CountryCode.GP, value: Countries[CountryType.Guadeloupe] },
        { key: CountryCode.GU, value: Countries[CountryType.Guam] },
        { key: CountryCode.GT, value: Countries[CountryType.Guatemala] },
        { key: CountryCode.GG, value: Countries[CountryType.Guernsey] },
        { key: CountryCode.GN, value: Countries[CountryType.Guinea] },
        { key: CountryCode.GW, value: Countries[CountryType.GuineaBissau] },
        { key: CountryCode.GY, value: Countries[CountryType.Guyana] },
        { key: CountryCode.HT, value: Countries[CountryType.Haiti] },
        { key: CountryCode.HM, value: Countries[CountryType.HeardIslandAndMcDonaldIslands] },
        { key: CountryCode.HN, value: Countries[CountryType.Honduras] },
        { key: CountryCode.HK, value: Countries[CountryType.HongKong] },
        { key: CountryCode.HU, value: Countries[CountryType.Hungary] },
        { key: CountryCode.IS, value: Countries[CountryType.Iceland] },
        { key: CountryCode.IN, value: Countries[CountryType.India] },
        { key: CountryCode.ID, value: Countries[CountryType.Indonesia] },
        { key: CountryCode.IR, value: Countries[CountryType.Iran] },
        { key: CountryCode.IQ, value: Countries[CountryType.Iraq] },
        { key: CountryCode.IE, value: Countries[CountryType.Ireland] },
        { key: CountryCode.IL, value: Countries[CountryType.Israel] },
        { key: CountryCode.IT, value: Countries[CountryType.Italy] },
        { key: CountryCode.CI, value: Countries[CountryType.IvoryCoast] },
        { key: CountryCode.JM, value: Countries[CountryType.Jamaica] },
        { key: CountryCode.JP, value: Countries[CountryType.Japan] },
        { key: CountryCode.JE, value: Countries[CountryType.Jersey] },
        { key: CountryCode.JO, value: Countries[CountryType.Jordan] },
        { key: CountryCode.KZ, value: Countries[CountryType.Kazakhstan] },
        { key: CountryCode.KE, value: Countries[CountryType.Kenya] },
        { key: CountryCode.KI, value: Countries[CountryType.Kiribati] },
        { key: CountryCode.KP, value: Countries[CountryType.KoreaDemRepublicOf] },
        { key: CountryCode.KW, value: Countries[CountryType.Kuwait] },
        { key: CountryCode.KG, value: Countries[CountryType.Kyrgyzstan] },
        { key: CountryCode.LA, value: Countries[CountryType.Laos] },
        { key: CountryCode.LV, value: Countries[CountryType.Latvia] },
        { key: CountryCode.LB, value: Countries[CountryType.Lebanon] },
        { key: CountryCode.LS, value: Countries[CountryType.Lesotho] },
        { key: CountryCode.LR, value: Countries[CountryType.Liberia] },
        { key: CountryCode.LY, value: Countries[CountryType.Libya] },
        { key: CountryCode.LI, value: Countries[CountryType.Liechtenstein] },
        { key: CountryCode.LT, value: Countries[CountryType.Lithuania] },
        { key: CountryCode.LU, value: Countries[CountryType.Luxemburg] },
        { key: CountryCode.MO, value: Countries[CountryType.Macau] },
        { key: CountryCode.MK, value: Countries[CountryType.Macedonia] },
        { key: CountryCode.MG, value: Countries[CountryType.Madagascar] },
        { key: CountryCode.MW, value: Countries[CountryType.Malawi] },
        { key: CountryCode.MY, value: Countries[CountryType.Malaysia] },
        { key: CountryCode.MV, value: Countries[CountryType.Maldives] },
        { key: CountryCode.ML, value: Countries[CountryType.Mali] },
        { key: CountryCode.MT, value: Countries[CountryType.Malta] },
        { key: CountryCode.IM, value: Countries[CountryType.ManIsland] },
        { key: CountryCode.MH, value: Countries[CountryType.MarshallIslands] },
        { key: CountryCode.MQ, value: Countries[CountryType.Martinique] },
        { key: CountryCode.MR, value: Countries[CountryType.Mauritania] },
        { key: CountryCode.MU, value: Countries[CountryType.Mauritius] },
        { key: CountryCode.YT, value: Countries[CountryType.Mayotte] },
        { key: CountryCode.MX, value: Countries[CountryType.Mexico] },
        { key: CountryCode.FM, value: Countries[CountryType.Micronesia] },
        { key: CountryCode.MD, value: Countries[CountryType.Moldova] },
        { key: CountryCode.MC, value: Countries[CountryType.Monaco] },
        { key: CountryCode.MN, value: Countries[CountryType.Mongolia] },
        { key: CountryCode.ME, value: Countries[CountryType.Montenegro] },
        { key: CountryCode.MS, value: Countries[CountryType.Montserrat] },
        { key: CountryCode.MA, value: Countries[CountryType.Morocco] },
        { key: CountryCode.MZ, value: Countries[CountryType.Mozambique] },
        { key: CountryCode.NA, value: Countries[CountryType.Namibia] },
        { key: CountryCode.NR, value: Countries[CountryType.Nauru] },
        { key: CountryCode.NP, value: Countries[CountryType.Nepal] },
        { key: CountryCode.NL, value: Countries[CountryType.Netherlands] },
        { key: CountryCode.AN, value: Countries[CountryType.NetherlandsAntilles] },
        { key: CountryCode.NC, value: Countries[CountryType.NewCaledonia] },
        { key: CountryCode.NZ, value: Countries[CountryType.NewZealand] },
        { key: CountryCode.NI, value: Countries[CountryType.Nicaragua] },
        { key: CountryCode.NE, value: Countries[CountryType.Niger] },
        { key: CountryCode.NG, value: Countries[CountryType.Nigeria] },
        { key: CountryCode.NU, value: Countries[CountryType.Niue] },
        { key: CountryCode.NF, value: Countries[CountryType.NorfolkIsland] },
        { key: CountryCode.MP, value: Countries[CountryType.NorthernMarianaIslands] },
        { key: CountryCode.NO, value: Countries[CountryType.Norway] },
        { key: CountryCode.OM, value: Countries[CountryType.Oman] },
        { key: CountryCode.PK, value: Countries[CountryType.Pakistan] },
        { key: CountryCode.PW, value: Countries[CountryType.Palau] },
        { key: CountryCode.PS, value: Countries[CountryType.PalestinianTerritories] },
        { key: CountryCode.PA, value: Countries[CountryType.Panama] },
        { key: CountryCode.PG, value: Countries[CountryType.PapuaNewGuinea] },
        { key: CountryCode.PY, value: Countries[CountryType.Paraguay] },
        { key: CountryCode.PE, value: Countries[CountryType.Peru] },
        { key: CountryCode.PH, value: Countries[CountryType.Philippines] },
        { key: CountryCode.PN, value: Countries[CountryType.Pitcairn] },
        { key: CountryCode.PL, value: Countries[CountryType.Poland] },
        { key: CountryCode.PT, value: Countries[CountryType.Portugal] },
        { key: CountryCode.PR, value: Countries[CountryType.PuertoRico] },
        { key: CountryCode.QA, value: Countries[CountryType.Qatar] },
        { key: CountryCode.RE, value: Countries[CountryType.ReunionIsland] },
        { key: CountryCode.RO, value: Countries[CountryType.Romania] },
        { key: CountryCode.RU, value: Countries[CountryType.RussianFederation] },
        { key: CountryCode.RW, value: Countries[CountryType.Rwanda] },
        { key: CountryCode.BL, value: Countries[CountryType.SaintBarthelemy] },
        { key: CountryCode.SH, value: Countries[CountryType.SaintHelenaAscensionAndTristan] },
        { key: CountryCode.KN, value: Countries[CountryType.SaintKittsAndNevis] },
        { key: CountryCode.LC, value: Countries[CountryType.SaintLucia] },
        { key: CountryCode.MF, value: Countries[CountryType.SaintMartin] },
        { key: CountryCode.PM, value: Countries[CountryType.SaintPierreAndMiquelon] },
        { key: CountryCode.VC, value: Countries[CountryType.SaintVincentAndTheGrenadines] },
        { key: CountryCode.WS, value: Countries[CountryType.Samoa] },
        { key: CountryCode.SM, value: Countries[CountryType.SanMarino] },
        { key: CountryCode.ST, value: Countries[CountryType.SaoTomeAndPrincipe] },
        { key: CountryCode.SA, value: Countries[CountryType.SaudiArabia] },
        { key: CountryCode.SN, value: Countries[CountryType.Senegal] },
        { key: CountryCode.RS, value: Countries[CountryType.Serbia] },
        { key: CountryCode.SC, value: Countries[CountryType.Seychelles] },
        { key: CountryCode.SL, value: Countries[CountryType.SierraLeone] },
        { key: CountryCode.SG, value: Countries[CountryType.Singapore] },
        { key: CountryCode.SX, value: Countries[CountryType.SintMaarten] },
        { key: CountryCode.SK, value: Countries[CountryType.Slovakia] },
        { key: CountryCode.SI, value: Countries[CountryType.Slovenia] },
        { key: CountryCode.SB, value: Countries[CountryType.SolomonIslands] },
        { key: CountryCode.SO, value: Countries[CountryType.Somalia] },
        { key: CountryCode.ZA, value: Countries[CountryType.SouthAfrica] },
        { key: CountryCode.GS, value: Countries[CountryType.SouthGeorgiaAndTheSouthSandwichIslands] },
        { key: CountryCode.KR, value: Countries[CountryType.SouthKorea] },
        { key: CountryCode.SS, value: Countries[CountryType.SouthSudan] },
        { key: CountryCode.ES, value: Countries[CountryType.Spain] },
        { key: CountryCode.LK, value: Countries[CountryType.SriLanka] },
        { key: CountryCode.SD, value: Countries[CountryType.Sudan] },
        { key: CountryCode.SR, value: Countries[CountryType.Suriname] },
        { key: CountryCode.SJ, value: Countries[CountryType.SvalbardAndJanMayen] },
        { key: CountryCode.SZ, value: Countries[CountryType.Swaziland] },
        { key: CountryCode.SE, value: Countries[CountryType.Sweden] },
        { key: CountryCode.CH, value: Countries[CountryType.Switzerland] },
        { key: CountryCode.SY, value: Countries[CountryType.Syria] },
        { key: CountryCode.TW, value: Countries[CountryType.Taiwan] },
        { key: CountryCode.TJ, value: Countries[CountryType.Tajikistan] },
        { key: CountryCode.TZ, value: Countries[CountryType.Tanzania] },
        { key: CountryCode.TH, value: Countries[CountryType.Thailand] },
        { key: CountryCode.TG, value: Countries[CountryType.Togo] },
        { key: CountryCode.TK, value: Countries[CountryType.Tokelau] },
        { key: CountryCode.TO, value: Countries[CountryType.Tonga] },
        { key: CountryCode.TT, value: Countries[CountryType.TrinidadAndTobago] },
        { key: CountryCode.TN, value: Countries[CountryType.Tunisia] },
        { key: CountryCode.TR, value: Countries[CountryType.Turkey] },
        { key: CountryCode.TM, value: Countries[CountryType.Turkmenistan] },
        { key: CountryCode.TC, value: Countries[CountryType.TurksAndCaicosIslands] },
        { key: CountryCode.TV, value: Countries[CountryType.Tuvalu] },
        { key: CountryCode.UG, value: Countries[CountryType.Uganda] },
        { key: CountryCode.UA, value: Countries[CountryType.Ukraine] },
        { key: CountryCode.AE, value: Countries[CountryType.UnitedArabEmirates] },
        { key: CountryCode.GB, value: Countries[CountryType.UnitedKingdom] },
        { key: CountryCode.US, value: Countries[CountryType.UnitedStates] },
        { key: CountryCode.UM, value: Countries[CountryType.UnitedStatesMinorOutlyingIslands] },
        { key: CountryCode.UY, value: Countries[CountryType.Uruguay] },
        { key: CountryCode.UZ, value: Countries[CountryType.Uzbekistan] },
        { key: CountryCode.VU, value: Countries[CountryType.Vanuatu] },
        { key: CountryCode.VA, value: Countries[CountryType.VaticanCityState] },
        { key: CountryCode.VE, value: Countries[CountryType.Venezuela] },
        { key: CountryCode.VN, value: Countries[CountryType.Vietnam] },
        { key: CountryCode.VG, value: Countries[CountryType.VirginIslandsBritish] },
        { key: CountryCode.VI, value: Countries[CountryType.VirginIslandsUS] },
        { key: CountryCode.WF, value: Countries[CountryType.WallisAndFutuna] },
        { key: CountryCode.EH, value: Countries[CountryType.WesternSahara] },
        { key: CountryCode.YE, value: Countries[CountryType.Yemen] },
        { key: CountryCode.ZM, value: Countries[CountryType.Zambia] },
        { key: CountryCode.ZW, value: Countries[CountryType.Zimbabwe] },
      ]
        .filter((country) => !excludedCountries.some((a) => a === country.key))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [Countries],
  );
}

export const useCountryTreeViewOptions = (
  selectedCountries: CountryCode[] = [],
  excludedCountries: CountryCode[] = [],
) => {
  const countries = useCountryOptions();

  return useMemo<TreeItemType<CountryCode>[]>(() => {
    return countries
      .filter((country) => !excludedCountries.length || !excludedCountries.some((a) => a === country.key))
      .map((item) => {
        const isDefault = Boolean(selectedCountries?.includes(item.key));

        return {
          key: item.key,
          value: item.value,
          expanded: true,
          selected: isDefault,
        };
      });
  }, [countries, selectedCountries, excludedCountries]);
};

export const useUserCountryOptions = () => {
  const userCountryCodes = useSelector(userSelectors.currentUserMarketCountries);
  const countries = useCountryOptions();

  return useMemo(() => {
    if (!userCountryCodes) return [];
    return countries.filter((country) => userCountryCodes.includes(country.key));
  }, [countries, userCountryCodes]);
};

export const useAvailableCountryOptions = (availableCountries: CountryCode[]) => {
  const countries = useCountryOptions();

  return useMemo(() => {
    if (!availableCountries.length) {
      return [];
    }
    return countries.filter((country) => availableCountries.includes(country.key));
  }, [countries, availableCountries]);
};

export const useCountryNameByCountryType = (country?: CountryCode) => {
  const countries = useCountryOptions();

  return countries.find((x) => x.key === country)?.value ?? '';
};
