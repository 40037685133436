import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';
import { AddressValidationResultModel } from '@/Models/AddressValidationResultModel';
import { selectorUtils } from '@/Utils';

const shippingLabelModalState = (state: RootState) => state.modalWindows.shippingLabelModalState;

const loadingState = createSelector(shippingLabelModalState, (state) => state.loadingType);

const isLoaded = selectorUtils.createIsLoadedSelector(loadingState);

const validationConflicts = createSelector(shippingLabelModalState, (state) => state.validationConflicts);

const validationConflictsCount = createSelector(validationConflicts, (conflicts): number => conflicts.length);

const currentShippingLabelStep = createSelector(shippingLabelModalState, (state) => state.currentStep);

const isOwnContract = createSelector(shippingLabelModalState, (state) => state.isOwnContract);

const isEditing = createSelector(shippingLabelModalState, (state) => state.isEditing);

const shouldShowValidationFinishedToast = createSelector(
  shippingLabelModalState,
  (state) => state.shouldShowValidationFinishedToast,
);

const currentValidationConflictIndex = createSelector(
  shippingLabelModalState,
  (state): number => state.currentConflictIndex,
);

const currentValidationConflict = createSelector(
  [validationConflicts, currentValidationConflictIndex],
  (conflicts, index): AddressValidationResultModel => conflicts?.[index],
);

const activeLogisticTypes = createSelector(shippingLabelModalState, (state) => state.activeLogisticTypes);

export const shippingLabelModalSelectors = {
  validationConflicts,
  validationConflictsCount,
  currentValidationConflictIndex,
  currentValidationConflict,
  isLoaded,
  currentShippingLabelStep,
  shouldShowValidationFinishedToast,
  isOwnContract,
  isEditing,
  activeLogisticTypes,
};
