import { MandatModel } from '@/Models/Mandat/MandatModel';
import { Nullable } from '@/Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mandatsAsyncThunks } from './asyncThunks';

export type MandatSettingsState = {
  mandats: MandatModel[];
  selectedMandat: Nullable<MandatModel>;
  overviewActiveStep: number;
  modalActiveStep: number;
};

const initialState: MandatSettingsState = {
  mandats: [],
  selectedMandat: null,
  overviewActiveStep: 0,
  modalActiveStep: 0,
};

const mandatSettingsSlice = createSlice({
  name: 'mandatSettings',
  initialState,
  reducers: {
    selectMandat(state, action: PayloadAction<MandatModel | null>) {
      state.selectedMandat = action.payload;
    },
    setOverviewStep(state, action: PayloadAction<number>) {
      state.overviewActiveStep = action.payload;
    },
    setModalStep(state, action: PayloadAction<number>) {
      state.modalActiveStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mandatsAsyncThunks.getMandats.fulfilled, (state, action) => {
        state.mandats = action.payload.mandats;
      })
      .addCase(mandatsAsyncThunks.createMandat.fulfilled, (state, action) => {
        state.mandats.push(action.payload.mandat);
        state.selectedMandat = action.payload.mandat;
      })
      .addCase(mandatsAsyncThunks.updateMandat.fulfilled, (state, action) => {
        state.mandats = state.mandats.map((i) => {
          if (i.id === action.payload.mandat.id) {
            return action.payload.mandat;
          } // if

          return i;
        });

        state.selectedMandat = action.payload.mandat;
      })
      .addCase(mandatsAsyncThunks.deleteMandat.fulfilled, (state, action) => {
        state.mandats = state.mandats.filter((item) => item.id != action.payload.id);
        state.selectedMandat = null;
      });
  },
});

const { actions, reducer } = mandatSettingsSlice;

export const mandatSettingsActions = actions;
export const mandatSettingsReducer = reducer;
export const mandatSettingsAsyncActions = mandatsAsyncThunks;
