import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function usePackagingAutomationSettings() {
  const {
    tours: {
      ShippingAutomationSettings: { Packaging },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{Packaging.Step1.Text}</div>,
      placement: 'auto',
      target: '#packagingAutomation',
      title: Packaging.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Packaging.Step2.Text}</div>,
      placement: 'auto',
      target: '#categoryForm',
      title: Packaging.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          maxWidth: '430px',
        },
      },
    },
    {
      content: <div>{Packaging.Step3.Text}</div>,
      placement: 'auto',
      target: '#quantity',
      title: Packaging.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          marginTop: '-10px',
        },
      },
    },
    {
      content: <div>{Packaging.Step4.Text}</div>,
      placement: 'auto',
      target: '#package',
      title: Packaging.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          minWidth: '320px',
          marginTop: '-10px',
        },
      },
    },
    {
      content: <div>{Packaging.Step5.Text}</div>,
      placement: 'auto',
      target: '#addNewPackageForRule',
      title: Packaging.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Packaging.Step6.Text}</div>,
      placement: 'auto',
      target: '#addCategoryPackagingForm',
      title: Packaging.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Packaging.Step7.Text}</div>,
      placement: 'auto',
      target: '#addPackagingRule',
      title: Packaging.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Packaging.Step8.Text}</div>,
      placement: 'auto',
      target: '#addNewPackage',
      title: Packaging.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    defaultTour,
  };
}
