import React, { useEffect, useMemo } from 'react';
import { OrderAutomatizaionRow, Pair } from '@/Types';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { useRequired } from '@/Hooks/Validation';
import { useLocalization } from '@/Hooks';
import { useOrderPackagesLoader } from './hooks/useOrderPackagesLoader';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { OwnContractLogistic } from './modules/OwnContractLogistic/OwnContractLogistic';
import { ShipCheaperLogistic } from './modules/ShipCheaperLogistic/ShipCheaperLogistic';
import styles from './OrderPackagesCell.scss';
import { shippingLabelModalActions, shippingLabelModalSelectors } from '@/ModalWindows/ShippingLabelModal/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@/Components/Icon/Icon';
import { TopMenuIcons } from '@/Static/IconsRes';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import { userSelectors } from '@/Redux/User';
import { orderLabelPrintModalSelectors } from '@/ModalWindows/OrderLabelPrintModal/services';
import { LogisticType } from '@/Enums';

type Props = {
  rowIndex: number;
  rowData: OrderAutomatizaionRow;
};

export function OrderPackagesCell({ rowIndex, rowData }: Props) {
  const {
    common: { InputPlaceholders },
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelectors.currentUser);
  const orderLabelPrintRows = useSelector(orderLabelPrintModalSelectors.orderLabelPrintRows);
  const activeLogisticTypes = useSelector(shippingLabelModalSelectors.activeLogisticTypes);

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  const required = useRequired();

  const ownContract = watch(`prepareLabels.${rowIndex}.ownContract`);

  useOrderPackagesLoader(rowData, ownContract);

  useEffect(() => {
    dispatch(shippingLabelModalActions.setOwnContract(ownContract === 'yes'));
  }, [ownContract]);

  const orderLabel = orderLabelPrintRows.find((item) => item.userOrderId == rowData.userOrderId);
  const needShowOwnContract = activeLogisticTypes.filter((item) => item != LogisticType.ShipCheaper).length > 0;
  const needShowShipCheaperContract = activeLogisticTypes.includes(LogisticType.ShipCheaper);

  const contractTypeOptions = useMemo(() => {
    const result: Pair<string>[] = [{ key: '', value: InputPlaceholders.Select }];

    if (needShowOwnContract) {
      result.push({ key: 'yes', value: orderLinkWithProduct.Options.OwnShippingContract });
    }

    if (user?.canUsePacklink && needShowShipCheaperContract) {
      result.push({
        key: 'no',
        value: orderLinkWithProduct.Options.OrderShippingLableByShipCheaper,
      });
    }

    return result;
  }, [InputPlaceholders, needShowOwnContract, needShowShipCheaperContract, orderLinkWithProduct, user?.canUsePacklink]);

  return (
    <div className={styles.packagesWrapper}>
      <div className={styles.packageSelectWithIcon}>
        <FormSelectInput<FormData>
          name={(`prepareLabels.${rowIndex}.ownContract` as unknown) as keyof FormData}
          register={register}
          options={contractTypeOptions}
          needClearButton={false}
          rules={required}
          error={errors}
          className={styles.select}
          disabled={orderLabel?.isPrinted}
        />

        <Icon
          alt={''}
          src={TopMenuIcons.SettingsIcon}
          className={styles.selectInputIcon}
          onClick={() => globalHistory.push(PagesRouting.SettingsPages.LogisticSettings)}
        />
      </div>

      <>
        {ownContract === 'yes' && (
          <OwnContractLogistic rowIndex={rowIndex} rowData={rowData} disabled={orderLabel?.isPrinted} />
        )}
        {ownContract === 'no' && (
          <ShipCheaperLogistic rowIndex={rowIndex} rowData={rowData} disabled={orderLabel?.isPrinted} />
        )}
      </>
    </div>
  );
}
