import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useMarketplaceSettings() {
  const {
    tours: {
      MarketplaceSettings: { Default, Account },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{Default.Step1.Text}</div>,
      placement: 'auto',
      target: '#mpTitle',
      title: Default.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step2.Text}</div>,
      placement: 'auto',
      target: '#mpTable',
      title: Default.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: Default.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: Default.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{Default.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: Default.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step6.Text}</div>,
      placement: 'auto',
      target: '#mpForm',
      title: Default.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const manualAccountTour: TourStep[] = [
    {
      content: <div>{Account.Step1.Text}</div>,
      placement: 'auto',
      target: '#mpModalTitle',
      title: Account.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step2.Text}</div>,
      placement: 'auto',
      target: '#mpModalFields',
      title: Account.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step3.Text}</div>,
      placement: 'auto',
      target: '#merchantModalFulfillment',
      title: Account.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step4.Text}</div>,
      placement: 'auto',
      target: '#mpModalFulfillment',
      title: Account.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const accountTour: TourStep[] = [
    {
      content: <div>{Account.Step1.Text}</div>,
      placement: 'auto',
      target: '#mpModalTitle',
      title: Account.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step2.Text}</div>,
      placement: 'auto',
      target: '#mpModalFields',
      title: Account.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step3.Text}</div>,
      placement: 'auto',
      target: '#merchantModalFulfillment',
      title: Account.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step4.Text}</div>,
      placement: 'auto',
      target: '#mpModalFulfillment',
      title: Account.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step5.Text}</div>,
      placement: 'auto',
      target: '#syncModalField',
      title: Account.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step6.Text}</div>,
      placement: 'auto',
      target: '#settingModalFlags',
      title: Account.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Account.Step7.Text}</div>,
      placement: 'auto',
      target: '#invoiceModalFlags',
      title: Account.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const connectTour: TourStep[] = [
    {
      content: <div>{Account.Step8.Text}</div>,
      placement: 'auto',
      target: '#mpConnecting',
      title: Account.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    defaultTour,
    manualAccountTour,
    accountTour,
    connectTour,
  };
}
