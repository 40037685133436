import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetChartDataRequest } from '@/Api/Dashboard/Requests/GetChartDataRequest';
import { GetChartDataResponse } from '@/Api/Dashboard/Responses/GetChartDataResponse';
import { GetDashboardFilterResponse } from '@/Api/Dashboard/Responses/GetDashboardFilterResponse';
import { GetSalesDataResponse } from '@/Api/Dashboard/Responses/GetSalesDataResponse';

const url = ApiRoutingUtils.createUrl('dashboard');

const getChartData = (request: GetChartDataRequest): Promise<GetChartDataResponse> => {
  return rest.post(url, request);
};

const getDashboardFilter = (): Promise<GetDashboardFilterResponse> => {
  return rest.get(`${url}/filter`);
};

const getSalesData = (): Promise<GetSalesDataResponse> => {
  return rest.get(`${url}/sales`);
};

export const DashboardApi = {
  getChartData,
  getDashboardFilter,
  getSalesData,
};
