import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { SettingsPageState } from './reducer';

const settingsPage = (state: RootState) => state.settingsPage;

const accountSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.marketplacesSettings,
);

const documentTemplateSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.documentTemplateSettings,
);

const packagesSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.packagesSettings,
);

const shippingAutomationSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.shippingAutomationSettings,
);

const addressBookSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.addressBookSettings,
);

const paymentMethodsSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.paymentMethodsSettings,
);

const emailTemplatesSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.emailTemplatesSettings,
);

const feedbackSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.feedbackSettings,
);

const userAccountSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.userAccountSettings,
);

const orderAutomationSettings = createSelector(
  settingsPage,
  (settingsPageState: SettingsPageState) => settingsPageState.orderAutomationSettings,
);

const mandatSettings = createSelector(settingsPage, (settingsPage: SettingsPageState) => settingsPage.mandatSettings);

export const settingsPageSelectors = {
  settingsPage,
  accountSettings,
  documentTemplateSettings,
  packagesSettings,
  shippingAutomationSettings,
  addressBookSettings,
  paymentMethodsSettings,
  emailTemplatesSettings,
  feedbackSettings,
  userAccountSettings,
  orderAutomationSettings,
  mandatSettings,
};
