import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from './reducer';
import { TranslationType, TranslationProducts, TranslationDocumentDesignerType } from '@/Localization/LanguageKeys';
import { convertLocalizationToLocale, LocalizationType, Locale } from '@/Enums';
import { Pair } from '@/Types';
import { selectorUtils } from '@/Utils';

const globalStateSelector = (root: RootState) => root.global;

const translation = createSelector(
  globalStateSelector,
  (globalState: GlobalState): TranslationType => globalState.translation,
);

const translationDocumentDesigner = createSelector(
  globalStateSelector,
  (globalState: GlobalState): TranslationDocumentDesignerType => globalState.translationDocumentDesigner,
);

const translationProducts = createSelector(
  globalStateSelector,
  (globalState: GlobalState): TranslationProducts => globalState.translationProducts,
);

const localization = createSelector(
  globalStateSelector,
  (globalState: GlobalState): LocalizationType => globalState.selectedLanguage.key,
);

const locale = createSelector(
  globalStateSelector,
  (globalState: GlobalState): Locale => convertLocalizationToLocale(globalState.selectedLanguage.key),
);

const selectedLanguage = createSelector(
  globalStateSelector,
  (globalState: GlobalState): Pair<LocalizationType> => globalState.selectedLanguage,
);

const loadingType = createSelector(globalStateSelector, (state) => state.loadingType);

const isLoading = selectorUtils.createIsLoadingSelector(loadingType);

const isConnectionLost = createSelector(globalStateSelector, (state) => state.isConnectionLost);

const countriesUnderSanction = createSelector(globalStateSelector, (state) => state.countriesUnderSanction);

export const globalSelectors = {
  translation,
  translationDocumentDesigner,
  translationProducts,
  localization,
  locale,
  selectedLanguage,
  isLoading,
  isConnectionLost,
  countriesUnderSanction,
};
