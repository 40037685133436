import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useDispatch } from 'react-redux';
import { mandatSettingsActions } from '@/Pages/Settings/modules/MandatsSettings/services/reducer';

export function useMandatSettings() {
  const {
    tours: {
      MandatsSettings: { Default, LegalNotice },
    },
  } = useLocalization();

  const dispatch = useDispatch();

  const defaultTour: TourStep[] = [
    {
      content: <div>{Default.Step1.Text}</div>,
      placement: 'auto',
      target: '#stepTitle',
      title: Default.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step2.Text}</div>,
      placement: 'auto',
      target: '#mandatsTable',
      title: Default.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: Default.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: Default.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{Default.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: Default.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setOverviewStep(0)),
    },
    {
      content: <div>{Default.Step6.Text}</div>,
      placement: 'auto',
      target: '#mandatsFormBlock',
      title: Default.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step7.Text}</div>,
      placement: 'auto',
      target: '#mandatInfo',
      title: Default.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step8.Text}</div>,
      placement: 'auto',
      target: '#legalNoticeStep',
      title: Default.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setOverviewStep(1)),
    },
    {
      content: <div>{Default.Step9.Text}</div>,
      placement: 'auto',
      target: '#addressInfo',
      title: Default.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step10.Text}</div>,
      placement: 'auto',
      target: '#vatRatesInfo',
      title: Default.Step10.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step11.Text}</div>,
      placement: 'auto',
      target: '#bankAccountInfo',
      title: Default.Step11.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step12.Text}</div>,
      placement: 'auto',
      target: '#logoInfo',
      title: Default.Step12.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step13.Text}</div>,
      placement: 'auto',
      target: '#documentsInfo',
      title: Default.Step13.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setOverviewStep(2)),
    },
    {
      content: <div>{Default.Step14.Text}</div>,
      placement: 'auto',
      target: '#documentsNextNumberInfo',
      title: Default.Step14.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step15.Text}</div>,
      placement: 'auto',
      target: '#emailInfo',
      title: Default.Step15.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setOverviewStep(3)),
    },
    {
      content: <div>{Default.Step16.Text}</div>,
      placement: 'auto',
      target: '#freeTextInfo',
      title: Default.Step16.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setOverviewStep(4)),
    },
    {
      content: <div>{Default.Step17.Text}</div>,
      placement: 'auto',
      target: '#navButtons',
      title: Default.Step17.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const mandatSettingsTour: TourStep[] = [
    {
      content: <div>{LegalNotice.Step1.Text}</div>,
      placement: 'auto',
      target: '#modalStepTitle',
      title: LegalNotice.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step2.Text}</div>,
      placement: 'auto',
      target: '#mandatBlock',
      title: LegalNotice.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step3.Text}</div>,
      placement: 'auto',
      target: '#modalNavButtons',
      title: LegalNotice.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step4.Text}</div>,
      placement: 'auto',
      target: '#legalNoticeModalStep',
      title: LegalNotice.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setModalStep(1)),
    },
    {
      content: <div>{LegalNotice.Step5.Text}</div>,
      placement: 'auto',
      target: '#invoiceAddressBlock',
      title: LegalNotice.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step6.Text}</div>,
      placement: 'auto',
      target: '#addAddressButton',
      title: LegalNotice.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step7.Text}</div>,
      placement: 'auto',
      target: '#contactsBlock',
      title: LegalNotice.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step8.Text}</div>,
      placement: 'auto',
      target: '#vatRatesBlock',
      title: LegalNotice.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step9.Text}</div>,
      placement: 'auto',
      target: '#bankAccountBlock',
      title: LegalNotice.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step10.Text}</div>,
      placement: 'auto',
      target: '#logoBLock',
      title: LegalNotice.Step10.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{LegalNotice.Step11.Text}</div>,
      placement: 'auto',
      target: '#documentsBlock',
      title: LegalNotice.Step11.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setModalStep(2)),
    },
    {
      content: <div>{LegalNotice.Step12.Text}</div>,
      placement: 'auto',
      target: '#emailBlock',
      title: LegalNotice.Step12.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setModalStep(3)),
    },
    {
      content: <div>{LegalNotice.Step13.Text}</div>,
      placement: 'auto',
      target: '#freeTextBlock',
      title: LegalNotice.Step13.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(mandatSettingsActions.setModalStep(4)),
    },
  ];

  return {
    defaultTour,
    mandatSettingsTour,
  };
}
