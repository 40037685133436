import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';

// Types import
import { ClassNameProps } from '@/Types';

// Images import
import { ModalCloseIcon } from '@/Static';

// Styles import
import styles from './Modal.scss';
import { TopMenuIcons } from '@/Static/IconsRes';

type Props = {
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  needCloseIcon?: boolean;
  needRefDocsIcon?: boolean;
  onClose: () => void;
  onDocsClick?: () => void;
  title?: string;
  modalClassName?: string;
  overlayClassName?: string;
  isOpen?: boolean;
} & ClassNameProps;

export function Modal({
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = false,
  needCloseIcon = true,
  needRefDocsIcon = true,
  onClose,
  onDocsClick,
  className,
  children,
  title,
  isOpen = true,
  modalClassName,
  overlayClassName,
}: PropsWithChildren<Props>) {
  return (
    <ReactModal
      isOpen={isOpen}
      className={cn(styles.modal, modalClassName)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={onClose}
      overlayClassName={overlayClassName}
    >
      <div className={styles.modalButtons}>
        {needRefDocsIcon && (
          <img src={TopMenuIcons.Learn} alt="doc" className={styles.docsButton} onClick={onDocsClick} />
        )}
        {needCloseIcon && <ModalCloseIcon size={'small'} onClick={onClose} />}
      </div>

      {title && <h3 className={styles.modalTitle}>{title}</h3>}
      <div className={className}>{children}</div>
    </ReactModal>
  );
}
