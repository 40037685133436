import { useFormContext } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { OrderAutomatizationPackageModel } from '@/Models/OrderUserProducts/OrderAutomatizationPackageModel';
import { useCallback, useEffect, useState } from 'react';
import { orderAutomatizationAsyncActions } from '@/ModalWindows/OrderAutomatizationModal/services/reducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';

export const usePackageRowForm = (
  rowIndex: number,
  index: number,
  packages: OrderAutomatizationPackageModel[],
  isOwnContract?: boolean,
  orderId?: number,
) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const required = useRequired();

  const selectedPackageKey = watch(`prepareLabels.${rowIndex}.packages.${index}.key`);
  const selectedProductId = watch(`prepareLabels.${rowIndex}.userProductId`);

  const [isPackageChanged, setIsPackageChanged] = useState(false);

  useEffect(() => {
    register(`prepareLabels.${rowIndex}.packages.${index}.price`);
  }, []);

  useEffect(() => {
    if (selectedPackageKey) {
      setIsPackageChanged(true);
    }
  }, [selectedPackageKey]);

  useEffect(() => {
    const selectedPackage = packages?.find((p) => p.key === selectedPackageKey);
    if (selectedPackage) {
      setValue(`prepareLabels.${rowIndex}.packages.${index}.weight`, String(selectedPackage.weight), {
        shouldValidate: true,
      });

      setValue(
        `prepareLabels.${rowIndex}.packages.${index}.price`,
        selectedPackage.products.find((p) => p.id === selectedProductId)?.userProductPrice ?? 0,
      );
    } // if
  }, [selectedPackageKey, selectedProductId, packages]);

  const loadPacklinkProducts = useCallback(() => {
    const selectedPackage = packages?.find((p) => p.key === selectedPackageKey);

    if (orderId && selectedPackage?.packageId && !isOwnContract) {
      dispatch(
        orderAutomatizationAsyncActions.getLogisticProducts({
          orderId: orderId,
          packageId: selectedPackage?.packageId,
        }),
      );
    }

    setIsPackageChanged(false);
  }, [orderId, packages, selectedPackageKey]);

  return {
    methods: {
      register,
      setValue,
      watch,
      errors,
      loadPacklinkProducts,
    },
    rules: {
      required,
    },
    options: {
      isPackageChanged,
    },
  };
};
