import { useLocalization } from 'src/Hooks';
import { useAppRouting } from 'src/Hooks/useAppRouting';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from 'src/Pages/Sales/services';
import { purchasesPageSelectors } from 'src/Pages/Purchases/services';
import { globalSelectors } from 'src/Redux/Global/selectors';
import { useMemo } from 'react';
import { localizationUtils } from 'src/Utils/localizationUtils';
import { FieldState } from 'src/Types';
import { orderTableRowUtils } from 'src/Types/OrderTableRowUtils';
import { accountSettingsSelectors } from '@/Pages/Settings/modules/MarketplacesSettings/services/selectors';

export const useInvoiceTooltipMessage = (): FieldState => {
  const {
    models: {
      OrderTableRow: { OrderNumber, InvoiceNumber, parts, CommonErrors },
    },
  } = useLocalization();
  const { isSalesPage, isPurchasesPage } = useAppRouting();
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedPurchases = useSelector(purchasesPageSelectors.selectedPurchases);
  const localization = useSelector(globalSelectors.localization);
  const mpAccounts = useSelector(accountSettingsSelectors.mpAccounts);

  return useMemo(() => {
    let disabledDocumentMessage: string | undefined;

    // Check marketplace credential start
    const orders = isSalesPage ? selectedSales : selectedPurchases;

    if (isSalesPage) {
      const mpAccountIds = orders.map((item) => item.mpAccountId);
      const invoiceCreateDisabled = mpAccountIds.some(
        (id) => !mpAccounts.find((account) => account.id === id)?.allowCreateInvoices,
      );

      if (invoiceCreateDisabled) {
        return {
          value: '',
          isDocumentIconDisabled: invoiceCreateDisabled,
          disabledDocumentMessage: InvoiceNumber.InvoiceCreateDisabledForMpAccountMessage,
        };
      }
    }
    // Check marketplace credential end

    const result = orders.reduce(
      (res, current) => {
        if (current.currentInvoiceDate) {
          res.withOrderNumbers.add(current.currentInvoiceNumber ?? '');
        }
        if (orderTableRowUtils.isOrderIsAmazonPending(current)) {
          res.amazonInProcessOrders.add(current.userOrderId.toString());
        }

        return res;
      },
      {
        withOrderNumbers: new Set<string>(),
        amazonInProcessOrders: new Set<string>(),
      },
    );

    const withOrderNumbers = [...result.withOrderNumbers];
    const amazonInProcessOrders = [...result.amazonInProcessOrders];

    if (withOrderNumbers.length) {
      disabledDocumentMessage = localizationUtils.insertTranslatedParamsToString(
        OrderNumber.DisableHeaderDocumentMessage,
        [withOrderNumbers.join(', '), withOrderNumbers.length],
        parts,
        localization,
      );
    } else if (amazonInProcessOrders.length) {
      disabledDocumentMessage = CommonErrors.AmazonOrderPending.DisableHeaderDocumentMessage;
    } // if

    return {
      value: '',
      isDocumentIconDisabled: Boolean(disabledDocumentMessage) || isPurchasesPage,
      disabledDocumentMessage,
    };
  }, [localization, selectedSales, selectedPurchases, isSalesPage, isPurchasesPage, mpAccounts]);
};
