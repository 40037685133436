import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import styles from '../../Tour.scss';
import { ModalCloseIcon } from '@/Static';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useLocalization } from '@/Hooks';
import { TourStep } from '@/Components/Tour/Tour';

export const Tooltip = ({
  backProps,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  size,
}: TooltipRenderProps & {
  step: TourStep;
}) => {
  const {
    buttons: { Previous, Next, Close, More },
  } = useLocalization();

  return (
    <div {...tooltipProps} className={styles.tooltip}>
      <ModalCloseIcon size={'small'} onClick={skipProps.onClick} className={styles.closeBtn} />

      <div className={styles.content}>
        <div>
          {step.title && <h4 className={styles.title}>{step.title}</h4>}
          <p className={styles.text}>{step.content}</p>
        </div>

        <div className={styles.buttonsWrapper}>
          {step.link && (
            <a href={step.link} target="_blank" rel="noreferrer">
              {More}
            </a>
          )}

          <div className={styles.actions}>
            {index > 0 && <Button theme={BUTTON_THEMES.TRANSPARENT} text={Previous} onClick={backProps.onClick} />}

            <Button
              theme={BUTTON_THEMES.SUCCESS}
              text={isLastStep ? Close : `${Next} ${index + 1} / ${size}`}
              onClick={primaryProps.onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
