import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shippingLabelModalActions, shippingLabelModalAsyncActions, shippingLabelModalSelectors } from './services';
import { ModalProps } from '@/Types';
import { salesPageActions, salesPageSelectors } from '@/Pages/Sales/services';
import { AddressValidationResolvingModal } from '@/ModalWindows/AddressValidationResolvingModal/AddressValidationResolvingModal';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { OrderAutomatizaionModal } from '../OrderAutomatizationModal/OrderAutomatizationModal';
import { ShippingLabelStep } from '@/ModalWindows/ShippingLabelModal/Types';
import { OrderLabelPrintModal } from '../OrderLabelPrintModal/OrderLabelPrintModal';
import { orderLabelPrintActions } from '../OrderLabelPrintModal/services';
import { genLogisticTypesDataAsyncActions } from '@/Redux/DynamicData/GenLogisticTypesData/reducer';
import { orderAutomatizationActions } from '../OrderAutomatizationModal/services/reducer';
import { GetActiveLogisticTypesResponse } from '@/Api/LogisticContracts/GetActiveLogisticTypesResponse';
import { EmptyActiveLogisticsModal } from '@/ModalWindows/ShippingLabelModal/modules/EmptyActiveLogisticsModal/EmptyActiveLogisticsModal';

export function ShippingLabelModal({ closeModalWindow }: ModalProps) {
  const dispatch: AppDispatch = useDispatch();
  const selectedOrders = useSelector(salesPageSelectors.selectedSales);

  const currentConflict = useSelector(shippingLabelModalSelectors.currentValidationConflict);
  const currentConflictIndex = useSelector(shippingLabelModalSelectors.currentValidationConflictIndex);
  const validationConflictsCount = useSelector(shippingLabelModalSelectors.validationConflictsCount);
  const currentStep = useSelector(shippingLabelModalSelectors.currentShippingLabelStep);
  const shouldShowValidationFinishedToast = useSelector(shippingLabelModalSelectors.shouldShowValidationFinishedToast);
  const [showEmptyActiveLogistics, setShowEmptyActiveLogistics] = useState(false);

  const onCloseModal = () => {
    closeModalWindow();
    shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.Close);
  };

  useEffect(() => {
    dispatch(shippingLabelModalAsyncActions.getActiveLogisticTypes()).then((response) => {
      const activeLogisticTypes = (response.payload as GetActiveLogisticTypesResponse)?.types;

      if (activeLogisticTypes?.length) {
        dispatch(
          shippingLabelModalAsyncActions.validateOrderAddresses({
            orderIds: selectedOrders.map((o) => o.id),
          }),
        );
        dispatch(genLogisticTypesDataAsyncActions.getLogisticTypes());
      } else {
        setShowEmptyActiveLogistics(true);
      }
    });

    return () => {
      dispatch(shippingLabelModalActions.resetState());
      dispatch(orderAutomatizationActions.resetState());
      dispatch(orderLabelPrintActions.resetState());
    };
  }, []);

  if (shouldShowValidationFinishedToast) {
    dispatch(shippingLabelModalActions.setShouldShowValidationFinishedToast(false));
  }

  if (showEmptyActiveLogistics) {
    return (
      <EmptyActiveLogisticsModal
        onClose={() => {
          setShowEmptyActiveLogistics(false);
          onCloseModal();
        }}
      />
    );
  }

  switch (currentStep) {
    case ShippingLabelStep.Validation:
      return (
        <AddressValidationResolvingModal
          closeModalWindow={closeModalWindow}
          currentConflict={currentConflict}
          currentConflictIndex={currentConflictIndex}
          totalConflictsCount={validationConflictsCount || 0}
          onSubmit={(orderId, addressModel) => {
            dispatch(
              shippingLabelModalAsyncActions.applyAddressChanges({ orderId, appliedAddress: addressModel }),
            ).then((response: any) => {
              if (response.error) {
                return;
              }
              dispatch(salesPageActions.updateValidatedAddress({ orderId, address: addressModel }));
            });
          }}
        />
      );

    case ShippingLabelStep.OrderWithoutAutomatizaion:
      return <OrderAutomatizaionModal isSales={true} closeModalWindow={onCloseModal} />;

    case ShippingLabelStep.OrderLabelPrint:
      return <OrderLabelPrintModal isSales={true} closeModalWindow={onCloseModal} />;

    case ShippingLabelStep.None:
      return null;

    case ShippingLabelStep.Close:
    default:
      closeModalWindow();
      return null;
  }
}
